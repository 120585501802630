import React from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import {flbusGetCancelationLimit} from 'flbus'

const Text = styled.div`
    font-size: 16px;
    line-height: 1.5;
    margin: 20px 0;
`

interface IComponent {
    id: string,
    title: string,
    start: string,
    category: string,
    booked: boolean,
    onConfirm: (id: string, category: string, title: string, start: string) => void,
    onCancel: (id: string, category: string) => void,
    onClose: () => void
}

const setTime = (date: Date, hours: number) => {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000)
    return date
}

const EventPopup = (props: IComponent) => {
    const intl = useIntl()

    const limit = flbusGetCancelationLimit()

    const target = new Date(props.start)
    const current = new Date()
    const cancel = target > setTime(new Date(), limit)
    const past = current > target

    const date = intl.formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    const onConfirm = () => {
        props.onClose()
        props.onConfirm(props.id, props.category, props.title, props.start)
    }

    const onCancel = () => {
        props.onClose()
        props.onCancel(props.id, props.category)
    }
    
    return (
        <div>
            {props.booked ?
                <React.Fragment>
                    {past ?
                        <Text>
                            {intl.formatMessage({id: "app.PastMeeting"}, {title: props.title, date: date, time: time})}
                        </Text> : cancel ?
                        <React.Fragment>
                            <Text>
                                {intl.formatMessage({id: "app.CancelReservation"}, {title: props.title, date: date, time: time})}
                            </Text>
                            <Button
                                fullWidth
                                variant='contained'
                                color='warning'
                                onClick={onCancel}
                            >
                                {intl.formatMessage({id: 'app.Cancel'})}
                            </Button>
                        </React.Fragment> :
                        <Text>
                            {intl.formatMessage({id: "app.ContactUs"}, {limit: limit})}
                        </Text>
                    }
                </React.Fragment> :
                <React.Fragment>
                    <Text>
                        {intl.formatMessage({id: "app.Meeting"})} {props.title} {intl.formatMessage({id: "app.On"})} {date} {intl.formatMessage({id: "calendar.At"})} {time}
                    </Text>
                    <Button
                        fullWidth
                        variant='contained'
                        color='success'
                        onClick={onConfirm}
                    >
                        {intl.formatMessage({id: 'app.Reserve'})}
                    </Button>
                </React.Fragment>
            }
        </div>
    )
}

export default React.memo(EventPopup) 