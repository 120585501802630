import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {authInitialize} from 'slices/auth'
import {messageReset} from 'slices/message'

const Root = () => {
    const authPending = useAppSelector(state => state.auth.pending)
    const bookingPending = useAppSelector(state => state.booking.pending)
    const timetablePending = useAppSelector(state => state.timetable.pending)

    const text = useAppSelector(state => state.message.text)
    const variant = useAppSelector(state => state.message.variant)
    const open = useAppSelector(state => state.message.open)

    const intl = useIntl()

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(authInitialize())
    }, [dispatch])

    const onClose = (e: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        dispatch(messageReset())
    }

    const pending = authPending || bookingPending || timetablePending

    return (
        <React.Fragment>
            {pending && <LinearProgress color='warning' sx={{position: 'absolute', width: '100%', zIndex: 1111}} />}
            <Outlet />
            <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
                <Alert variant='filled' severity={variant} sx={{ width: '100%' }}>
                    {intl.formatMessage({id: text})}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default Root
