import {createSlice} from '@reduxjs/toolkit'
import {authLogin, authRegister, authInitialize, authCodeConfirm} from 'slices/auth'
import {specialistsFetch, doctorsTimeslotsFetch, doctorTimeslotReserve, nursingTimeslotReserve,
    mothersTimeslotReserve, zonesFetch} from 'slices/booking'
import {patientTimeslotReserve} from 'slices/timetable'    
import messages from 'enums/messages'
import {FlbusError} from 'flbus'

interface IMessage {
    text: string,
    variant: 'error' | 'info' | 'success' | 'warning',
    open: boolean
}

const initialState: IMessage = {
    text: messages.RegularError,
    variant: 'error',
    open: false
}

const messageSlice = createSlice({
    name: 'message',
    initialState: initialState,
    reducers: {
        messageReset: state => {
            state.open = false
        },
        messageSet: (state, action) => {
            state.text = action.payload.text
            state.variant = action.payload.variant
            state.open = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authLogin.fulfilled, (state, action) => {
                if (!action.payload) {
                    state.text = messages.WrongCredentials
                    state.variant = 'error'
                    state.open = true
                }
            })
            .addCase(authLogin.rejected, (state) => {
                state.text = messages.LoginError
                state.variant = 'error'
                state.open = true
            })

            .addCase(authRegister.rejected, (state, action) => {
                state.text = action.error.message === FlbusError.UserExists ? messages.UserExists : messages.RegistritionFailed
                state.variant = 'error'
                state.open = true
            })

            .addCase(authInitialize.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(specialistsFetch.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(doctorsTimeslotsFetch.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(zonesFetch.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(doctorTimeslotReserve.rejected, (state, action) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(nursingTimeslotReserve.rejected, (state, action) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(mothersTimeslotReserve.rejected, (state, action) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(authCodeConfirm.rejected, (state, action) => {
                state.text = action.error.message === FlbusError.NotVerified ? messages.WrongCode : messages.RegistritionFailed
                state.variant = 'error'
                state.open = true
            })

            .addCase(patientTimeslotReserve.rejected, (state, action) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })
    },
})

export const {messageReset, messageSet} = messageSlice.actions

export default messageSlice