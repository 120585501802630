import React from 'react'
import styled from '@emotion/styled'
import {useTheme} from '@mui/material/styles'
import Person from '@mui/icons-material/Person'

const Container = styled.div<{textColor: string, sticky?: boolean}>`
    width: 100%;
    align-items: center;
    color: ${props => props.textColor};
    cursor: pointer;
    display: flex;
    font-size: 13px;
    padding: 10px 20px;
    position: ${props => props.sticky ? 'sticky' : ''};
    top: ${props => props.sticky ? 0 : ''};
    background: ${props => props.sticky ? '#1e1e26' : ''};

    :hover {
        background: #18181e;
    }
`

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    :not(:last-child) {
        margin-right: 20px;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Subject = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    :not(:last-child) {
        padding-bottom: 5px;
    }
`

const Desc = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const Text = styled.div`
    overflow: hidden;
`

const Label = styled.div<{color: string}>`
    font-weight: 700;
    margin-left: auto;
    color: ${props => props.color};
`

interface IComponent {
    id: number,
    active: boolean,
    icon?: string,
    subject: string,
    description?: string,
    label?: string,
    sticky?: boolean,
    customIcon?: JSX.Element
    onClick: (id: number) => void,
    style?: string
}

const ListItem = (props: IComponent) => {
    const theme = useTheme()

    const onClick = () => {
        props.onClick(props.id)
    }
    
    return (
        <Container
            key={props.id}
            onClick={onClick}
            sticky={props.sticky}
            textColor={props.active ? theme.palette.secondary.main : theme.palette.info.dark}
            style={props.style ? {
                position: 'absolute',
                top: props.style
            } : {}}
        >
            <Icon>
                {props.customIcon ? props.customIcon : props.icon ?
                    <Image src={props.icon} /> :
                    <Person sx={{fontSize: 30}} />
                }
            </Icon>
            <Text>
                <Subject>{props.subject}</Subject>
                {props.description &&
                    <Desc>{props.description}</Desc>
                }
            </Text>
            {props.label &&
                <Label color={theme.palette.success.main}>{props.label}</Label>
            }
        </Container>
    )
}

export default React.memo(ListItem) 