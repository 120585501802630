import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useNavigate, useParams, useLocation, useOutletContext} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from '@emotion/styled'
import Drawer from '@mui/material/Drawer'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {specialistsFetch, doctorsTimeslotsFetch,
    nursingTimeslotsFetch, doctorsTimeslotCancel, nursingTimeslotCancel} from 'slices/booking'
import Sidebar from 'components/Sidebar'
import ListItem from 'components/ListItem'
import FormPopup from 'components/FormPopup'
import Calendar from 'components/Calendar'
import EventPopup from 'components/EventPopup'
import EventsFilter from 'components/EventsFilter'
import {ILang} from 'interfaces/intl'
import {Event, EventHandlers} from 'interfaces/calendar'
import {palette} from 'enums/palette'
import {specialities} from 'enums/services'
import routes from 'enums/routes'
import {HeaderHeight} from 'enums/variables'
import {IEvent, ProfessionalType} from 'flbus'

const Container = styled.div<{mobile: boolean}>`
    display: flex;
    height: 100%;

    .fc-toolbar-chunk {
        min-width: 100px;
    }

    ${props => props.mobile && `
        .fc .fc-toolbar-title {
            font-size: 1em;
        }

        .fc-toolbar-chunk {
            min-width: 0;
        }
    `}
`

interface IContext {
    mobileFilter: boolean;
    openFilter: (open: boolean) => void
}

const Booking = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const intl = useIntl()
    const {category} = useParams()

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const context: IContext = useOutletContext()
    
    const speciality = category || specialities.medicine.value

    const calendarRef = useRef<EventHandlers>(null)

    const [filter, setFilter] = useState<number | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [event, setEvent] = useState<{id: string, title: string, start: string, category: string}>({id: '', title: '', start: '', category: ''})

    const authorized = useAppSelector(state => state.auth.authorized)
    const initialized = useAppSelector(state => state.auth.initialized)
    const specialists = useAppSelector(state => state.booking.specialists)
    const slots = useAppSelector(state => state.booking.slots)
    const user = useAppSelector(state => state.auth.user)
    const patientId = user && user.patient ? user.patient.id : null

    const events = useMemo(() => {
        const currentEvents = speciality === specialities.medicine.value || speciality === specialities.therapy.value ? slots.doctors : slots.nursing
        const doctors = specialists[speciality]

        if (doctors && currentEvents) {
            return currentEvents.reduce((data: Event[], slot: IEvent) => {
                const doctor = doctors ? doctors.find(doctor => doctor.id === slot.ownerId) : undefined
    
                if (doctor) {
                    const index = doctors.findIndex(doctor => doctor.id === slot.ownerId)
                    const color = palette[index % palette.length]
    
                    data.push({
                        id: slot.id,
                        title: doctor ? doctor.name : '',
                        start: slot.start,
                        end: slot.end,
                        ownerId: slot.ownerId,
                        consumerId: slot.consumerId,
                        backgroundColor: slot.consumerId ? `rgba(${color}, 0.7)` : `rgba(${color}, 0.1)`,
                        borderColor: mobile ? slot.consumerId ? `rgba(${color}, 1)` : `rgba(${color}, 0.3)` : `rgba(${color})`,
                        textColor: slot.consumerId ? `rgb(255, 255, 255)` : `rgba(${color})`
                    })
                }
    
                return data 
            }, [])
        }

        return []
    }, [slots, specialists, speciality, mobile])

    const targetEvents = useMemo(() => {
        if (filter) {
            return events.filter(event => event.ownerId === filter)
        } else {
            return events
        }
        
    }, [events, filter])

    useEffect(() => {
        if (initialized) {
            const doctors = speciality === specialities.medicine.value || speciality === specialities.therapy.value

            if (!specialists[speciality]) {
                dispatch(specialistsFetch(speciality as ProfessionalType))
            }

            if (doctors) {
                dispatch(doctorsTimeslotsFetch(patientId))
            } else {
                dispatch(nursingTimeslotsFetch(patientId))
            }
        }
    }, [initialized, speciality, patientId, dispatch])

    const onListItemClick = useCallback((id: number) => {
        if (mobile) {
            context.openFilter(false)
        }

        if (filter === id) {
            setFilter(null)
        } else {
            setFilter(id)
        }
    }, [filter, mobile])
    
    const onConfirm = (id: string, category: string, title: string, start: string) => {
        calendarRef.current!.listPopupClose()

        if (authorized && patientId) {
            setOpen(true)
            setEvent({id, title, start, category})
        } else {
            navigate(`/${routes.Login}`, {state: {from: location.pathname}})
        }
    }

    const onCancel = (slotId: string, category: string) => {
        calendarRef.current!.listPopupClose()

        if (patientId) {
            if (category === specialities.medicine.value || speciality === specialities.therapy.value) {
                dispatch(doctorsTimeslotCancel({patientId, slotId}))
            } else {
                dispatch(nursingTimeslotCancel({patientId, slotId}))
            }
        }
    }

    const onFormPopupClose = useCallback(() => {
        setOpen(false)
    }, [])

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = events.find(item => item.id === id)
        const booked = event ? !!event.consumerId : false

        if (event) {
            return <EventPopup
                id={event.id}
                title={event.title}
                start={event.start}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onClose={onClose}
                category={speciality}
                booked={booked}
            />
        }
    }

    const onChangeSpeciality = () => {
        setFilter(null)
    }

    const onFilterClose = () => {
        context.openFilter(false)
    }
    
    return (
        <React.Fragment>
        {mobile && <EventsFilter
            speciality={speciality}
            mobile={mobile}
            onChangeSpeciality={onChangeSpeciality}
        />}
        <Container mobile={mobile}>
            {!mobile &&
                <Sidebar
                    width={400}
                    itemsComponent={specialists[speciality] && specialists[speciality].map(item =>
                        <ListItem
                            key={item.id}
                            active={item.id === filter}
                            id={item.id}
                            subject={item.name}
                            description={item.spec}
                            icon={item.image}
                            onClick={onListItemClick}
                        />
                    )}
                    topComponent={
                        <EventsFilter
                            speciality={speciality}
                            onChangeSpeciality={onChangeSpeciality}
                        />
                    }
                />
            }
            <Drawer
                anchor='bottom'
                open={context.mobileFilter}
                onClose={onFilterClose}
                PaperProps={{sx: {
                    height: `calc(100% - ${HeaderHeight}px)`,
                    background: '#3c3d4c'
                }}}
            >
                <div>
                    {specialists[speciality] && specialists[speciality].map(item =>
                        <ListItem
                            key={item.id}
                            active={item.id === filter}
                            id={item.id}
                            subject={item.name}
                            description={item.spec}
                            icon={item.image}
                            onClick={onListItemClick}
                        />
                    )}
                </div>
            </Drawer>
            <Calendar
                defaultView={mobile ? 'listMonth' : 'dayGridMonth'}
                locale={intl.locale as ILang}
                events={targetEvents}
                eventPopupContent={eventPopupContent}
                ref={calendarRef}
                modeChangeControl={mobile ? false : true}
                listEmptyContent={intl.formatMessage({id: 'app.NoTimeslots'})}
            />
            <FormPopup open={open} event={event} mobile={mobile} onClose={onFormPopupClose} />
        </Container>
        </React.Fragment>
    )
}

export default Booking
