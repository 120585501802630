import {useState, useCallback, useMemo, createContext} from 'react'
import {Outlet} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import {LocalStorage} from '@framelink/utils'
import messagesEn from 'intl/en.json'
import messagesPt from 'intl/pt.json'
import {ILang, ILangs, IMessages, ILangContext} from 'interfaces/intl'

const langs: ILangs = {
    en: {
        key: 'en',
        label: 'English'
    },
    pt: {
        label: 'Português',
        key: 'pt'
    }
}

const messages: IMessages = {
    en: messagesEn,
    pt: messagesPt
}

const defaultLocale: ILang = LocalStorage.get('locale') || langs.en.key

const defaultLangContext = {
    lang: defaultLocale,
    langs: langs,
    changeLang: () => {}
}

export const LangContext = createContext<ILangContext>(defaultLangContext)

const Intl = () => {
    const [lang, setLang] = useState<ILang>(defaultLocale)

    const changeLang = useCallback((value: ILang) => {
        setLang(value)
        LocalStorage.set('locale', value)
    }, [])

    const contextValue = useMemo(() => ({
        lang,
        langs,
        changeLang
    }), [lang, changeLang])

    return (
        <IntlProvider messages={messages[lang]} locale={lang}>
            <LangContext.Provider value={contextValue}>
                <Outlet />
            </LangContext.Provider>
        </IntlProvider>
    )
}

export default Intl
