import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Navigate
} from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles'
import Auth from 'containers/Auth'
import Intl from 'containers/Intl'
import Login from 'containers/Login'
import Booking from 'containers/Booking'
import Root from 'containers/Root'
import Container from 'containers/Container'
import Timetable from 'containers/Timetable'
import routes from 'enums/routes'
import theme from 'theme'

const Router = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Intl />}>
                <Route element={<Root />}>
                    <Route path={`/${routes.Login}`} element={<Login registration={false} />} />
                    <Route path={`/${routes.Registration}`} element={<Login registration={true} />} />
                    <Route element={<Container />}>
                        <Route path={`/${routes.Book}/:category?`} element={<Auth role='patient'><Booking /></Auth>} />
                        <Route path={`/${routes.Timetable}`} element={<Auth role='pro'><Timetable /></Auth>} />
                    </Route>
                    <Route path='*' element={<Navigate to={`/${routes.Book}`} replace={true} />} />
                </Route>
            </Route>
        )
    )

    return <RouterProvider router={router} />
}

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    )
}

export default App
