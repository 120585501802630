import React from 'react'
import {Navigate} from 'react-router-dom'
import {useAppSelector} from 'app/hooks'
import routes from 'enums/routes'

type Role = 'pro' | 'patient'

const Auth = (props: {role: Role, children: JSX.Element}) => {
    const authorized = useAppSelector(state => state.auth.authorized)
    const initialized = useAppSelector(state => state.auth.initialized)
    const user = useAppSelector(state => state.auth.user)
    const proId = user && user.professional ? user.professional.id : 0
    const patientId = user && user.patient ? user.patient.id : 0

    // if (!initialized) return props.children

    return (
        <React.Fragment>
            {props.role === 'pro' ?
                <React.Fragment>
                    {initialized ? authorized ? proId ? props.children :
                        <Navigate to={`/${routes.Book}`} replace={true} /> :
                        <Navigate to={`/${routes.Login}`} replace={true} state={{from: `/${routes.Timetable}`}} /> : props.children
                    }
                </React.Fragment> :
                <React.Fragment>
                    {initialized ? authorized ? patientId ? props.children :
                        <Navigate to={`/${routes.Timetable}`} replace={true} /> :
                        props.children : props.children
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Auth
