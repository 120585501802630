import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import {timeslotReserve} from 'slices/timetable'
import {useAppDispatch} from 'app/hooks'
import {Patients} from 'interfaces/forms'

const Input = styled(TextField)(({theme}) => ({
    marginBottom: '10px',
    marginTop: '10px'
}))

interface IComponent {
    open: boolean,
    event: {
        slotId: string,
        patientId: number | null,
        start: string,
        name: string,
        nif: string,
        phone: string
    }
    matchCheck: (nif: string) => {nif: string, name: string, phone: string, id: number} | null
    onClose: () => void,
    mobile: boolean
}

const PatientForm = (props: IComponent) => {
    const [form, setForm] = useState<Patients>({
        name: props.event ? props.event.name : '',
        phone: props.event ? props.event.phone : '',
        nif: props.event ? props.event.nif : ''
    })

    const [matchId, setId] = useState<number | null>(null)

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const onConfirm = () => {
        const id = props.event.patientId || matchId
        dispatch(timeslotReserve({
            patientId: id ? id : 0,
            slotId: props.event.slotId,
            patient: props.event.patientId || matchId ? null : form
        }))

        onClose()
    }

    const onClose = () => {
        setForm({
            nif: '',
            name: '',
            phone: ''
        })

        props.onClose()
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'nif') {
            if (e.target.value.match(/[^0-9]/)) {
                e.preventDefault()
                return
            }

            if (e.target.value.length === 9) {
                const data = props.matchCheck(e.target.value)

                if (data) {
                    setForm({
                        nif: data.nif,
                        name: data.name,
                        phone: data.phone
                    })
                    setId(data.id)
                    return
                }
            }

            setId(null)
            setForm({
                ...form,
                nif: e.target.value
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const date = intl.formatDate(props.event.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.event.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    const patientId = props.event.patientId
    const disabled = !patientId && (!form.nif || form.nif.length !== 9 || !form.name || !form.phone)

    return (
        <Dialog open={!!props.open} scroll='body' onClose={props.onClose} fullScreen={props.mobile}>
            <DialogTitle>
                {date} {intl.formatMessage({id: "calendar.At"})} {time}
            </DialogTitle>
            <DialogContent>
                <Input
                    name='nif'
                    value={patientId ? props.event.nif : form.nif}
                    inputProps={{inputMode: 'numeric', maxLength: 9}}
                    disabled={!!patientId}
                    onChange={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.Nif'})}
                />
                <Input
                    name='name'
                    value={patientId ? props.event.name : form.name}
                    disabled={!!patientId || !!matchId}
                    onChange={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientName'})}
                />
                <Input
                    name='phone'
                    value={patientId ? props.event.phone : form.phone}
                    disabled={!!patientId || !!matchId}
                    onChange={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientPhone'})}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {intl.formatMessage({id: 'app.Cancel'})}
                </Button>
                <Button onClick={onConfirm} disabled={disabled} variant='contained'>
                    {intl.formatMessage({id: 'app.Confirm'})}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(PatientForm) 