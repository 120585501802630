import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IProfessional, IEvent, IZone, flbusGetProfessionals, flbusGetDoctorsSlots,
    flbusGetNursingSlots, flbusGetZones, flbusReserveDoctorSlot, flbusReserveNursingSlot,
    flbusGetMotherCareServices, flbusReserveMotherCareSlot, flbusCancelDoctorsSlot, flbusCancelNursingSlot,
    IMotherCareService, ProfessionalType, ReservationDoctors, ReservationNursing,
    ReservationMotherCare} from 'flbus'

interface IBooking {
    specialists: {
        [key: string]: IProfessional[]
    },
    slots: {
        [key: string]: IEvent[]
    },
    pending: boolean,
    zones: IZone[],
    motherOptions: IMotherCareService[]
}

const initialState: IBooking = {
    specialists: {},
    slots: {},
    pending: false,
    zones: [],
    motherOptions: []
}

export const specialistsFetch = createAsyncThunk('booking/specialistsFetch', async (value: ProfessionalType) => flbusGetProfessionals(value))

export const doctorsTimeslotsFetch = createAsyncThunk('booking/doctorsTimeslotsFetch', async (patientId: number | null) => flbusGetDoctorsSlots(patientId))

export const nursingTimeslotsFetch = createAsyncThunk('booking/nursingTimeslotsFetch', async (patientId: number | null) => flbusGetNursingSlots(patientId))

export const zonesFetch = createAsyncThunk('booking/zonesFetch', async () => flbusGetZones())

export const doctorTimeslotReserve = createAsyncThunk('booking/doctorTimeslotReserve', async (data: {
    patientId: number, slotId: string, data: ReservationDoctors}) => flbusReserveDoctorSlot(data.patientId, data.slotId, data.data))

export const nursingTimeslotReserve = createAsyncThunk('booking/nursingTimeslotReserve', async (data: {
    patientId: number, slotId: string, data: ReservationNursing}) => flbusReserveNursingSlot(data.patientId, data.slotId, data.data))

export const mothersTimeslotReserve = createAsyncThunk('booking/mothersTimeslotReserve', async (data: {
    patientId: number, slotId: string, data: ReservationMotherCare}) => flbusReserveMotherCareSlot(data.patientId, data.slotId, data.data))

export const motherServicesFetch = createAsyncThunk('booking/motherServicesFetch', async () => flbusGetMotherCareServices())

export const doctorsTimeslotCancel = createAsyncThunk('booking/doctorsTimeslotCancel', async (data: {
    patientId: number, slotId: string}) => flbusCancelDoctorsSlot(data.patientId, data.slotId))

export const nursingTimeslotCancel = createAsyncThunk('booking/nursingTimeslotCancel', async (data: {
    patientId: number, slotId: string}) => flbusCancelNursingSlot(data.patientId, data.slotId))

const bookingSlice = createSlice({
    name: 'booking',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(specialistsFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(specialistsFetch.fulfilled, (state, action) => {
                state.specialists[action.meta.arg] = action.payload
                state.pending = false
            })
            .addCase(specialistsFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(doctorsTimeslotsFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(doctorsTimeslotsFetch.fulfilled, (state, action) => {
                state.slots.doctors = action.payload
                state.pending = false
            })
            .addCase(doctorsTimeslotsFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(doctorTimeslotReserve.pending, (state, action) => {
                state.pending = true
            })
            .addCase(doctorTimeslotReserve.fulfilled, (state, action) => {
                const index = state.slots.doctors.findIndex(slot => slot.id === action.payload.id)

                state.slots.doctors[index] = action.payload
                state.pending = false
            })
            .addCase(doctorTimeslotReserve.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(nursingTimeslotReserve.pending, (state, action) => {
                state.pending = true
            })
            .addCase(nursingTimeslotReserve.fulfilled, (state, action) => {
                const index = state.slots.nursing.findIndex(slot => slot.id === action.payload.id)

                state.slots.nursing[index] = action.payload
                state.pending = false
            })
            .addCase(nursingTimeslotReserve.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(mothersTimeslotReserve.pending, (state, action) => {
                state.pending = true
            })
            .addCase(mothersTimeslotReserve.fulfilled, (state, action) => {
                const index = state.slots.nursing.findIndex(slot => slot.id === action.payload.id)

                state.slots.nursing[index] = action.payload
                state.pending = false
            })
            .addCase(mothersTimeslotReserve.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(nursingTimeslotsFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(nursingTimeslotsFetch.fulfilled, (state, action) => {
                state.slots.nursing = action.payload
                state.pending = false
            })
            .addCase(nursingTimeslotsFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(zonesFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(zonesFetch.fulfilled, (state, action) => {
                state.zones = action.payload
                state.pending = false
            })
            .addCase(zonesFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(motherServicesFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(motherServicesFetch.fulfilled, (state, action) => {
                state.motherOptions = action.payload
                state.pending = false
            })
            .addCase(motherServicesFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(doctorsTimeslotCancel.pending, (state, action) => {
                state.pending = true
            })
            .addCase(doctorsTimeslotCancel.fulfilled, (state, action) => {
                const index = state.slots.doctors.findIndex(slot => slot.id === action.payload.id)

                state.slots.doctors[index] = action.payload
                state.pending = false
            })
            .addCase(doctorsTimeslotCancel.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(nursingTimeslotCancel.pending, (state, action) => {
                state.pending = true
            })
            .addCase(nursingTimeslotCancel.fulfilled, (state, action) => {
                const index = state.slots.nursing.findIndex(slot => slot.id === action.payload.id)

                state.slots.nursing[index] = action.payload
                state.pending = false
            })
            .addCase(nursingTimeslotCancel.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default bookingSlice