import {ChangeEvent, FormEvent, useEffect, useState, Fragment} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {userExistCheck, authCodeConfirm, authRegistrationCancel} from 'slices/auth'
import {flbusRequestVerificationCode} from 'flbus'

const Text = styled.div`
    font-size: 16px;
    line-height: 1.5;
    margin: 20px 0;
`

const Link = styled.span`
    font-size: 13px;
    color: #0084c3;
    letter-spacing: 0.5px;
    cursor: pointer;
    display: inline-block;
    display: flex;
    justify-content: center;
`

const style = {
    marginBottom: '20px'
}

interface IForm {
    nif: string,
    name: string,
    phone: string,
    password: string,
    repeat: string
}

const SignUp = () => {
    const [code, setCode] = useState<string>('')
    const [request, setRequest] = useState<boolean>(false)

    const [form, setForm] = useState<IForm>({
        nif: '',
        name: '',
        phone: '',
        password: '',
        repeat: ''
    })

    const [invalidPassword, setInvalidPassword] = useState<boolean>(false)

    const intl = useIntl()

    const pending = useAppSelector(state => state.auth.pending)
    const userExisting = useAppSelector(state => state.auth.userExisting)
    const userId = userExisting ? userExisting.id : null

    const dispatch = useAppDispatch()

    useEffect(() => {
        setRequest(false)

        if (userId) {
            setTimeout(() => {
                setRequest(true)
            }, 120000)
        }
    }, [userId])

    const onCodeRequest = () => {
        if (userId) {
            flbusRequestVerificationCode(userId)
            setRequest(false)

            setTimeout(() => {
                setRequest(true)
            }, 120000)
        }
    }

    const onSubmitForm = (e: FormEvent) => {
        e.preventDefault()

        if (form.password.trim() === form.repeat.trim()) {
            dispatch(userExistCheck({
                nif: form.nif.trim(),
                password: form.password.trim(),
                name: form.name.trim(),
                phone: form.phone.trim()
            }))

            setInvalidPassword(false)
        } else {
            setInvalidPassword(true)
        }
    }

    const onConfirm = (e: FormEvent) => {
        e.preventDefault()

        dispatch(authCodeConfirm({
            code: code.trim(),
            password: form.password.trim(),
            nif: form.nif,
            id: userId!
        }))
    }

    const onCancel = () => {
        dispatch(authRegistrationCancel())
    }

    const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value)
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'nif' && e.target.value.match(/[^0-9]/)) {
            e.preventDefault()

            return
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const disabled = !form.password || !form.repeat || !form.name || !form.phone || !form.nif || form.nif.length !== 9

    return (
        <Fragment>
            {userExisting ?
                <form onSubmit={onConfirm} method='post'>
                    <Text>
                        {intl.formatMessage({id: 'app.ConfirmationText'}, {phone: userExisting.phone})}
                    </Text>
                    <TextField
                        value={code}
                        fullWidth
                        name='code'
                        label={intl.formatMessage({id: 'app.Code'})}
                        onChange={onCodeChange}
                        sx={style}
                    />
                    <Button
                        fullWidth
                        variant='contained'
                        sx={style}
                        color='success'
                        onClick={onCodeRequest}
                        disabled={!request}
                    >
                        {intl.formatMessage({id: 'app.RequestCode'})}
                    </Button>
                    <Button
                        fullWidth
                        variant='contained'
                        type='submit'
                        sx={style}
                        disabled={!code}
                    >
                        {intl.formatMessage({id: 'app.Confirm'})}
                    </Button>
                    <Link onClick={onCancel}>{intl.formatMessage({id: 'app.ChangeDetails'})}</Link>
                </form> :
                <form onSubmit={onSubmitForm} method='post'>
                    <TextField
                        value={form.nif}
                        fullWidth
                        name='nif'
                        inputProps={{inputMode: 'numeric', maxLength: 9}}
                        label={intl.formatMessage({id: 'app.Nif'})}
                        disabled={pending}
                        onChange={onChange}
                        sx={{marginBottom: '20px', marginTop: '20px'}}
                    />
                    <TextField
                        value={form.name}
                        fullWidth
                        name='name'
                        label={intl.formatMessage({id: 'app.Name'})}
                        disabled={pending}
                        onChange={onChange}
                        sx={style}
                    />
                    <TextField
                        value={form.phone}
                        fullWidth
                        name='phone'
                        label={intl.formatMessage({id: 'app.Phone'})}
                        disabled={pending}
                        onChange={onChange}
                        sx={style}
                    />
                    <TextField
                        value={form.password}
                        fullWidth
                        name='password'
                        label={intl.formatMessage({id: 'app.Password'})}
                        disabled={pending}
                        error={invalidPassword}
                        type='password'
                        onChange={onChange}
                        sx={style}
                    />
                    <TextField
                        value={form.repeat}
                        fullWidth
                        name='repeat'
                        label={intl.formatMessage({id: 'app.RepeatPassword'})}
                        disabled={pending}
                        error={invalidPassword}
                        type='password'
                        onChange={onChange}
                        sx={style}
                    />
                    <Button
                        fullWidth
                        variant='contained'
                        type='submit'
                        sx={style}
                        disabled={disabled}
                    >
                        {intl.formatMessage({id: 'app.SignUp'})}
                    </Button>
                </form>
            }
        </Fragment>
    )
}

export default SignUp
