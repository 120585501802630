import axios, {AxiosResponse} from 'axios'
import {authLogout} from 'slices/auth'
import {messageSet} from 'slices/message'
import messages from 'enums/messages'

const API_URL = 'https://artesao.eu:8080'

axios.defaults.withCredentials = true

export const setUpInterceptor = (Store: any) => {
    axios.interceptors.response.use(undefined, (error: any) => {
        if (error) {
            if (error.response && error.response.status === 401) {
                // Store.dispatch({
                //     type: authLogout.fulfilled.type,
                //     payload: {auth: false, user: null} as ISignIn
                // })

                // Store.dispatch(messageSet({
                //     text: messages.Unauthorized,
                //     variant: 'error'
                // }))
            } else if (error.message === 'Network Error') {
                Store.dispatch(messageSet({
                    text: messages.ServerOff,
                    variant: 'error'
                }))
            } else if (error.response.status !== 422) {
                Store.dispatch(messageSet({
                    text: messages.RegularError,
                    variant: 'error'
                }))

                if (error.response && error.response.status === 500) {
                    console.log(error.response.data)
                }
            }
        }

        throw error
    })
}

const api = <T, D>(path: string, data?: T): Promise<AxiosResponse<D>> => {
    return axios({
        method: 'post',
        url: `${API_URL}/${path}`,
        data: data
    })
}

export default api
