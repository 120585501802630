import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {IPatient, ReservationDoctors} from 'flbus'
import {Doctors} from 'interfaces/forms'

const Input = styled(TextField)(({theme}) => ({
    marginBottom: '10px',
    marginTop: '10px'
}))

interface IComponent {
    data: IPatient | null,
    onConfirm: (data: ReservationDoctors) => void,
    onClose: () => void
}

const DoctorsForm = (props: IComponent) => {
    const [form, setForm] = useState<Doctors>({
        info: '',
        name: props.data ? props.data.name : '',
        phone: props.data ? props.data.phone : '',
        email: props.data ? props.data.email : ''
    })

    const intl = useIntl()

    const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onConfirm = () => {
        props.onConfirm(form)
    }
    
    const disabled = !form.name || !form.phone

    return (
        <React.Fragment>
            <DialogContent>
                <Input
                    name='info'
                    defaultValue={form.info}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.Info'})}
                    helperText={intl.formatMessage({id: 'app.SpecialInformation'})}
                />
                <Input
                    name='name'
                    defaultValue={form.name}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientName'})}
                />
                <Input
                    name='phone'
                    defaultValue={form.phone}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientPhone'})}
                />
                <Input
                    name='email'
                    defaultValue={form.email}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientEmail'})}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {intl.formatMessage({id: 'app.Cancel'})}
                </Button>
                <Button onClick={onConfirm} disabled={disabled} variant='contained'>
                    {intl.formatMessage({id: 'app.Confirm'})}
                </Button>
            </DialogActions>
        </React.Fragment>  
    )
}

export default React.memo(DoctorsForm) 