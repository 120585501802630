import React from 'react'
import styled from '@emotion/styled'
import {useTheme} from '@mui/material/styles'

const Container = styled.div<{width: number}>`
    flex: 0 0 auto;
    background: #3C3D4C;
    color: #FFFFFF;
    width: ${props => props.width}px;
    display: flex;
    flex-direction: column;
`

const List = styled.div`
    background: rgba(0, 0, 0, 0.3);
    overflow: scroll;
`

const ListItem = styled.div<{textColor: string}>`
    font-size: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    :hover {
        background: rgba(0, 0, 0, 0.5);
    }
`

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    :not(:last-child) {
        margin-right: 20px;
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Footer = styled.div`
    margin-top: auto;
`

interface IComponent {
    activeListItem?: number,
    itemsList?: IListItem[],
    itemsComponent?: JSX.Element[],
    listComponent?: JSX.Element,
    width: number,
    topComponent?: JSX.Element,
    bottomComponent?: JSX.Element,
    onClick?: (id: number) => void

}

interface IListItem {
    id: number,
    icon: string,
    subject: string
}

const Sidebar = (props: IComponent) => {
    const theme = useTheme()

    const onClick = (id: number) => {
        if (typeof props.onClick === 'function') {
            props.onClick(id)
        }
    }

    return (
        <Container width={props.width}>
            <div>{props.topComponent}</div>
            {props.listComponent ? props.listComponent :
                <List>
                    {props.itemsList && props.itemsList.map(item =>
                        <ListItem
                            key={item.id}
                            onClick={() => onClick(item.id)}
                            textColor={props.activeListItem === item.id ? theme.palette.primary.main : theme.palette.info.dark}
                        >
                            <Icon>
                                {item.icon &&
                                    <Image src={item.icon} />
                                }
                            </Icon>
                            <div>{item.subject}</div>
                        </ListItem>
                    )}
                    {props.itemsComponent && props.itemsComponent}
                </List>
            }
            <Footer>{props.bottomComponent}</Footer>
        </Container>
    )
}

export default Sidebar 