import dark from 'images/logo-dark.png'
import light from 'images/logo-light.png'

const variants = {
    dark: 'dark',
    light: 'light'
}

const Logo = (props: { width?: number, variant?: 'light' | 'dark' }) => {
    return <img src={props.variant === variants.light ? light : dark} style={{ width: props.width }} />
}

export default Logo 