import {ChangeEvent, FormEvent, useState} from 'react'
import {useIntl} from 'react-intl'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {authLogin} from 'slices/auth'

const style = {
    marginBottom: '20px'
}

const SignIn = () => {
    const [nif, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const intl = useIntl()

    const pending = useAppSelector(state => state.auth.pending)

    const dispatch = useAppDispatch()

    const onSubmitForm = (e: FormEvent) => {
        e.preventDefault()
        dispatch(authLogin({
            nif: nif.trim(),
            password: password.trim()
        }))
    }

    const onChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.match(/[^0-9]/)) {
            e.preventDefault()

            return
        }

        setLogin(e.target.value)
    }

    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

    return (
        <form onSubmit={onSubmitForm} method='post'>
            <TextField
                value={nif}
                fullWidth
                name='nif'
                inputProps={{inputMode: 'numeric', maxLength: 9}}
                label={intl.formatMessage({id: 'app.Nif'})}
                disabled={pending}
                onChange={onChangeLogin}
                sx={{marginBottom: '20px', marginTop: '20px'}}
            />
            <TextField
                value={password}
                fullWidth
                name='password'
                label={intl.formatMessage({id: 'app.Password'})}
                disabled={pending}
                type='password'
                onChange={onChangePassword}
                sx={style}
            />
            <Button
                fullWidth
                variant='contained'
                type='submit'
                sx={style}
                disabled={!nif || nif.length !== 9 || !password}
            >
                {intl.formatMessage({id: 'app.SignIn'})}
            </Button>
        </form>
    )
}

export default SignIn
