import React, {Fragment, useState, useMemo, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@mui/material/styles'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {useTheme} from '@mui/material/styles'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {motherServicesFetch} from 'slices/booking'
import {IPatient, IZone, ReservationMotherCare} from 'flbus'
import {locales} from 'enums/services'
import {Mothers} from 'interfaces/forms'

const Input = styled(TextField)(({theme}) => ({
    marginBottom: '10px',
    marginTop: '10px'
}))

interface IComponent {
    data: IPatient | null,
    zones: IZone[],
    onClose: () => void,
    onConfirm: (data: ReservationMotherCare) => void,
}

const MothersForm = (props: IComponent) => {
    const options = useAppSelector(state => state.booking.motherOptions)

    const [form, setForm] = useState<Mothers>({
        info: '',
        name: props.data ? props.data.name : '',
        phone: props.data ? props.data.phone : '',
        email: props.data ? props.data.email : '',
        isOutside: locales.center,
        zoneId: '',
        address: '',
        services: []
    })

    const theme = useTheme()
    const intl = useIntl()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!options.length) {
            dispatch(motherServicesFetch())
        }
    }, [dispatch, options.length])

    const onChange = (e: SelectChangeEvent<number[]> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.name === 'isOutside' && e.target.value === locales.center) {
            setForm({
                ...form,
                zoneId: '',
                address: '',
                isOutside: e.target.value as string
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const onConfirm = () => {
        const data = {
            ...form,
            isOutside: form.isOutside === locales.outside ? true : false,
            zoneId: form.zoneId as number
        }

        props.onConfirm(data)
    }

    const tax = useMemo(() => {
        if (form.zoneId) {
            const zone = props.zones.find(zone => zone.id === form.zoneId)
            return zone ? zone.tax : 0
        } else {
            return 0
        }
    }, [form.zoneId, props.zones])

    const optionsFilter = useMemo(() => {
        return options.filter(option => form.isOutside === locales.outside ? option.outside : option.center)
    }, [form.isOutside, options])

    const disabled = !form.name || !form.phone || !form.services.length ||
        (form.isOutside === locales.outside && (!form.zoneId || !form.address))

    return (
        <React.Fragment>
            <DialogContent>
                <Input
                    name='isOutside'
                    value={form.isOutside}
                    onChange={onChange}
                    fullWidth
                    select
                    label={intl.formatMessage({id: 'app.ServiceAt'})}
                >
                    {Object.values(locales).map(item => (
                        <MenuItem key={item} value={item}>
                            {intl.formatMessage({id: `app.${item}`})}
                        </MenuItem>
                    ))}
                </Input>
                {form.isOutside === locales.outside &&
                    <Fragment>
                        <Input
                            name='zoneId'
                            value={form.zoneId}
                            onChange={onChange}
                            fullWidth
                            select
                            label={intl.formatMessage({id: 'app.District'})}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 300
                                        }
                                    }
                                }
                            }}
                        >
                            {props.zones.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                            {/*<MenuItem key={props.zones.length + 1} value={props.zones.length + 1}>
                                {intl.formatMessage({id: 'app.ServiceOther'})}
                            </MenuItem>*/}
                        </Input>
                        <Input
                            name='address'
                            defaultValue={form.address}
                            onBlur={onChange}
                            fullWidth
                            label={intl.formatMessage({id: 'app.Address'})}
                        />
                    </Fragment>
                }
                <FormControl fullWidth sx={{margin: '10px 0'}}>
                    <InputLabel>{intl.formatMessage({id: 'app.ServiceType'})}</InputLabel>
                    <Select
                        name='services'
                        value={form.services}
                        onChange={onChange}
                        multiple
                        label={intl.formatMessage({id: 'app.ServiceType'})}
                        renderValue={(selected: number[]) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={optionsFilter.find(option => option.id === value)!.name} />
                                ))}
                            </Box>
                        )}
                    >
                        {optionsFilter.map(option => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Input
                    name='info'
                    defaultValue={form.info}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.Info'})}
                    helperText={intl.formatMessage({id: 'app.SpecialInformation'})}
                />
                <Input
                    name='name'
                    defaultValue={form.name}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientName'})}
                />
                <Input
                    name='phone'
                    defaultValue={form.phone}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientPhone'})}
                />
                <Input
                    name='email'
                    defaultValue={form.email}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientEmail'})}
                />
                <DialogContentText sx={{display: 'flex', justifyContent: 'space-between', color: theme.palette.success.dark}}>
                    <span>Subtotal</span>{tax ? `${tax} € + Serviço` : `Serviço`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {intl.formatMessage({id: 'app.Cancel'})}
                </Button>
                <Button variant='contained' disabled={disabled} onClick={onConfirm}>
                    {intl.formatMessage({id: 'app.Confirm'})}
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export default React.memo(MothersForm) 