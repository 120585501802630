import React from 'react'
import {useIntl} from 'react-intl'
import {formatDate, EventApi} from '@fullcalendar/core'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import Popover from '@mui/material/Popover'
import {IListPopup} from 'interfaces/calendar'

const Content = styled.div`
    max-width: 400px;
    min-width: 300px;
    margin: 20px;
`

const Title = styled.span`
    font-size: 13px;
    color: #a0b7be;
    letter-spacing: 0.5px;
`

const Bottom = styled.span`
    font-size: 13px;
    color: #a0b7be;
    letter-spacing: 0.5px;
    cursor: pointer;
`

const Event = styled.div<{background: string, border: string, text: string}>`
    margin: 2px 2px 0;
    background-color: ${props => props.background};
    border: 1px solid ${props => props.border};
    color: ${props => props.text};
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    padding: 1px;
`

const Time = styled.span`
	font-weight: bold;
	margin-right: 3px;
`

const List = styled.div`
	margin: 20px 0;
`

const ListPopup = (props: IListPopup) => {
    const intl = useIntl()
    const theme = useTheme()

    const date = props.date ? formatDate(props.date, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
        locale: intl.locale
    }) : ''

    const getTime = (date: Date | null) => {
        return date ? formatDate(date, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }) : ''
    }

    const onClick = (event: EventApi, el: HTMLElement) => {
        props.listPopupEventClick(event, el)
    }
    
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
            }}
        >
            <Content>
                <Title>
                    {date}
                </Title>
                <List>
                    {props.events.map(item => {
                        const event = item.event

                        return <Event
                            key={event.id}
                            onClick={(e) => onClick(event, e.target as HTMLElement)}
                            background={event.backgroundColor ? event.backgroundColor : theme.palette.primary.main}
                            border={event.borderColor ? event.borderColor : theme.palette.primary.main}
                            text={event.textColor ? event.textColor : theme.palette.info.main}
                        >
                            <Time>{getTime(event.start)}</Time>
                            <span>{event.title}</span>
                        </Event>	
                    })}
                </List>
                <Bottom onClick={props.modeChangeToWeek}>
                    {intl.formatMessage({id: "calendar.GoToWeek"})}
                </Bottom>
            </Content>
        </Popover>

    )
}

export default React.memo(ListPopup)