import {Specialities, Services} from 'interfaces/services'

export const specialities: Specialities = {
    medicine: {
        value: 'medicine',
        label: 'Convencional'
    },
    therapy: {
        value: 'therapy',
        label: 'NonConvencional'
    },
    nursing: {
        value: 'nursing',
        label: 'Nursing'
    },
    mothers: {
        value: 'mothers',
        label: 'Mothers'
    }
}

export const services: Services = {
    injection: {
        value: 'injection',
        label: 'Injection'
    },
    bandage: {
        value: 'bandage',
        label: 'Bandage'
    },
    ecg: {
        value: 'ecg',
        label: 'Ecg'
    },
    collect: {
        value: 'collect',
        label: 'Collect'
    },
    catheterization: {
        value: 'catheterization',
        label: 'Catheterization'
    },
    flushing: {
        value: 'flushing',
        label: 'Flushing'
    },
    other: {
        value: 'other',
        label: 'Other'
    }
}

export const locales = {
    center: 'Center',
    outside: 'Outside'
}