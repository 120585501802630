import React, {useRef, useState, useLayoutEffect} from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
    overflow: auto;
    height: 100%;
    position: relative;
`

interface IComponent <T>{
    children: (data: T, style: string) => JSX.Element,
    heightItem: number,
    data: T[]
}

const extra = 5

const ListVirtualized = <T extends unknown> (props: IComponent<T>) => {
    const container = useRef<HTMLDivElement>(null)
    const [count, setCount] = useState<number>(0)
    const [indexes, setIndexes] = useState<{start: number, end: number}>({
        start: 0,
        end: 0
    })

    useLayoutEffect(() => {
        if (container.current) {
            const {height} = container.current.getBoundingClientRect()
            const count = Math.ceil(height  / props.heightItem)
            setCount(count)
            setIndexes((indexes) => ({
                ...indexes,
                end: count + extra
            }))
        }
    }, [props.heightItem])

    const onScroll = () => {
        if (container.current) {
            const scrollTop = container.current.scrollTop
            const currentIndex = Math.trunc(scrollTop / props.heightItem)
            const start = Math.max(currentIndex - extra, 0)
            const end = currentIndex + count + extra

            if (start !== indexes.start || end !== indexes.end) {
                setIndexes({
                    start,
                    end
                })
            }
        }
    }

    const getList = () => {
        const elements = []

        for (let i = indexes.start; i < indexes.end; i++) {
            if (!props.data[i]) break

            elements.push(props.children(props.data[i], i * props.heightItem + 'px'))
        }

        return elements
    }

    const list = props.data.length ? getList() : []
    
    return (
        <Container onScroll={onScroll} ref={container}>
            <div style={{height: props.data.length * props.heightItem + 'px'}}>
                {list}
            </div>
        </Container>
    )
}

export default React.memo(ListVirtualized) as typeof ListVirtualized