import {useEffect, useState, useMemo, useCallback, useRef} from 'react'
import {useOutletContext} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from '@emotion/styled'
import Drawer from '@mui/material/Drawer'
import EventIcon from '@mui/icons-material/Event'
import {filter} from '@framelink/utils'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {timetableFetch} from 'slices/timetable'
import Sidebar from 'components/Sidebar'
import ListItem from 'components/ListItem'
import Calendar from 'components/Calendar'
import PatientForm from 'components/FormPopup/PatientForm'
import InputFilter from 'components/InputFilter'
import ListVirtualized from 'components/ListVirtualized'
import {ILang} from 'interfaces/intl'
import {Event, EventHandlers} from 'interfaces/calendar'
import {palette} from 'enums/palette'
import {HeaderHeight} from 'enums/variables'
import {IEvent, IPatient} from 'flbus'

const Container = styled.div<{mobile: boolean}>`
    display: flex;
    height: 100%;

    .fc-toolbar-chunk {
        min-width: 100px;
    }

    ${props => props.mobile && `
        .fc .fc-toolbar-title {
            font-size: 1em;
        }

        .fc-toolbar-chunk {
            min-width: 0;
        }
    `}
`

interface IContext {
    mobileFilter: boolean;
    openFilter: (open: boolean) => void
}

const Timetable = () => {
    const dispatch = useAppDispatch()
    const intl = useIntl()

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const context: IContext = useOutletContext()

    const calendarRef = useRef<EventHandlers>(null)

    const [targetPatient, setTargetPatient] = useState<number | null>(null)
    const [textFilter, setTextFilter] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const [event, setEvent] = useState<{
        slotId: string,
        patientId: number | null,
        start: string,
        name: string,
        nif: string,
        phone: string
    }>({slotId: '', patientId: null, start: '', name: '', nif: '', phone: ''})

    const patients = useAppSelector(state => state.timetable.patients)
    const slots = useAppSelector(state => state.timetable.slots)
    const initialized = useAppSelector(state => state.auth.initialized)
    const user = useAppSelector(state => state.auth.user)
    const doctorId = user && user.professional ? user.professional.id : 0

    useEffect(() => {
        if (initialized) {
            dispatch(timetableFetch(doctorId))
        }
    }, [initialized, doctorId, dispatch])

    const onListItemClick = useCallback((id: number) => {
        if (mobile) {
            context.openFilter(false)
        }

        if (targetPatient === id) {
            setTargetPatient(null)
        } else {
            setTargetPatient(id)
        }
    }, [targetPatient, mobile])

    const filterPatients = useCallback((value: string) => {
        console.log(value)
        setTargetPatient(null)
        setTextFilter(value)
    }, [])

    const Item = useCallback((data: IPatient, style: string) => {
        return <ListItem
            key={data.id}
            active={data.id === targetPatient}
            id={data.id}
            subject={data.name}
            description={data.nif}
            onClick={onListItemClick}
            style={style}
        />
    }, [targetPatient, onListItemClick])

    const eventClick = (id: string) => {
        const slot = slots.find(item => item.id === id)

        if (slot && !slot.consumerId) {
            const patient = patients.find(item => item.id === targetPatient)

            const event = {
                slotId: id,
                patientId: targetPatient,
                name: patient ? patient.name : '',
                nif: patient ? patient.nif : '',
                phone: patient ? patient.phone : '',
                start: slot.start
            }

            calendarRef.current!.listPopupClose()
            setEvent(event)
            setOpen(true)
        }
    }

    const matchCheck = (nif: string) => {
        const data = patients.find(item => item.nif === nif)
        return data ? {nif: data.nif, name: data.name, phone: data.phone, id: data.id} : null
    }

    const onFormPopupClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onFilterClose = () => {
        context.openFilter(false)
    }

    const events = useMemo(() => {
        if (patients && slots) {
            return slots.reduce((data: Event[], slot: IEvent) => {
                const patient = patients ? patients.find(patient => patient.id === slot.consumerId) : undefined
                const index = patient ? patients.findIndex(patient => patient.id === slot.consumerId) : 0
                const color = patient ? palette[index % palette.length] : '0, 132, 195'
    
                data.push({
                    id: slot.id,
                    title: patient ? patient.name : '',
                    start: slot.start,
                    end: slot.end,
                    consumerId: slot.consumerId,
                    backgroundColor: slot.consumerId ? `rgba(${color}, 0.7)` : `rgba(${color}, 0.1)`,
                    borderColor: mobile ? slot.consumerId ? `rgba(${color}, 1)` : `rgba(${color}, 0.3)` : `rgba(${color})`,
                    textColor: slot.consumerId ? `rgb(255, 255, 255)` : `rgba(${color})`
                })

                return data 
            }, [])
        }

        return []
    }, [slots, patients, mobile])

    const targetEvents = useMemo(() => {
        if (targetPatient === 0) {
            return events.filter(event => !event.consumerId)
        } else if (targetPatient) {
            return events.filter(event => event.consumerId === targetPatient || !event.consumerId)
        } else {
            return events
        }
        
    }, [events, targetPatient])

    const patientsFiltered = useMemo(() => {
        return filter(patients, textFilter, ['nif', 'name', 'phone'], false)
    }, [patients, textFilter])

    return (
        <Container mobile={mobile}>
            {!mobile &&
                <Sidebar
                    width={400}
                    listComponent={
                        <div style={{overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column', background: 'rgba(0, 0, 0, 0.3)'}}>
                            <ListItem
                                key={0} id={0}
                                active={0 === targetPatient}
                                subject={intl.formatMessage({id: 'app.AvailableTimeslots'})}
                                onClick={onListItemClick}
                                sticky
                                customIcon={<EventIcon sx={{fontSize: 30}} />}
                            />
                            <ListVirtualized data={patientsFiltered} heightItem={60}>{Item}</ListVirtualized>
                        </div>
                        
                    }
                    topComponent={
                        <InputFilter onChange={filterPatients} value={textFilter} />
                    }
                />
            }
            <Drawer
                anchor='bottom'
                open={context.mobileFilter}
                onClose={onFilterClose}
                PaperProps={{sx: {
                    height: `calc(100% - ${HeaderHeight}px)`,
                    background: '#3c3d4c'
                }}}
            >
                <InputFilter onChange={filterPatients} value={textFilter} />
                <div style={{overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column', background: 'rgba(0, 0, 0, 0.3)'}}>
                    <ListItem
                        key={0} id={0}
                        active={0 === targetPatient}
                        subject={intl.formatMessage({id: 'app.AvailableTimeslots'})}
                        onClick={onListItemClick}
                        sticky
                        customIcon={<EventIcon sx={{fontSize: 30}} />}
                    />
                    <ListVirtualized data={patientsFiltered} heightItem={60}>{Item}</ListVirtualized>
                </div>
            </Drawer>
            <Calendar
                defaultView={mobile ? 'listMonth' : 'dayGridMonth'}
                locale={intl.locale as ILang}
                events={targetEvents}
                eventClick={eventClick}
                ref={calendarRef}
                modeChangeControl={mobile ? false : true}
                listEmptyContent={intl.formatMessage({id: 'app.NoTimeslots'})}
            />
            <PatientForm open={open} event={event} mobile={mobile} matchCheck={matchCheck} onClose={onFormPopupClose} />
        </Container>
    )
}

export default Timetable
