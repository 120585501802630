import {configureStore} from '@reduxjs/toolkit'
import authSlice from 'slices/auth'
import messageSlice from 'slices/message'
import bookingSlice from 'slices/booking'
import timetableSlice from 'slices/timetable'
import {setUpInterceptor} from 'utils/api'

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        message: messageSlice.reducer,
        booking: bookingSlice.reducer,
        timetable: timetableSlice.reducer
    },
})

setUpInterceptor(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store