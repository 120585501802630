import React, {useContext, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {styled} from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Language from '@mui/icons-material/Language'
import FilterAlt from '@mui/icons-material/FilterAlt'
import Avatar from 'containers/Avatar'
import {LangContext} from 'containers/Intl'
import Logo from 'components/Logo'
import pattern from 'images/pattern.png'
import {ILang} from 'interfaces/intl'
import {HeaderHeight} from 'enums/variables'

const Header = styled(AppBar)(({ theme }) => ({
    backgroundImage: `url(${pattern})`,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'repeat'
}))

const Container = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [mobileFilter, openFilter] = useState<boolean>(false)

    const {lang, langs, changeLang} = useContext(LangContext)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
        changeLang(e.currentTarget.id as ILang)
        setAnchorEl(null)
    }

    const onFilterClick = () => {
        openFilter(true)
    }
    
    return (
        <React.Fragment>
            <Header position='fixed'>
                <Toolbar sx={{height: HeaderHeight}}>
                    <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                        <Logo width={100} variant='light' />
                    </Box>
                    {mobile &&
                        <IconButton color='info' onClick={onFilterClick}>
                            <FilterAlt />
                        </IconButton>
                    }
                    <IconButton color='info' onClick={handleClick}>
                        <Language />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {Object.keys(langs).map(item =>
                            <MenuItem key={langs[item].key} id={langs[item].key} selected={langs[item].key === lang} onClick={handleMenuItemClick}>
                                {langs[item].label}
                            </MenuItem>
                        )}
                    </Menu>
                    <Avatar />
                </Toolbar>
            </Header>
            <div style={{paddingTop: HeaderHeight + 'px', height: '100vh'}}>
                <Outlet context={{mobileFilter, openFilter}} />
            </div>
        </React.Fragment>
    )
}

export default Container
