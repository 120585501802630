export const palette = [
        '45, 74, 197',
        '255, 166, 0',
        '198, 44, 168',
        '255, 93, 79',
        '238, 36, 141',
        '255, 58, 110',
        '141, 61, 188',
        '255, 130, 46'
]
