import React, {ChangeEvent} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {styled as muiStyled} from '@mui/material/styles'
import {debounce} from '@framelink/utils'
import TextField from '@mui/material/TextField'

interface IComponent {
    value: string,
    onChange: (value: string) => void
}

const Container = styled.div`
    align-items: center;
    display: flex;
    padding: 20px;
    flex-direction: column;
`

const Input = muiStyled(TextField)(({theme}) => ({
    '& label': {
        color: theme.palette.info.dark
    },

    '& label.Mui-focused': {
        color:theme.palette.info.dark,
    },

    '& .MuiOutlinedInput-root': {
        color: theme.palette.info.dark,

        '& fieldset': {
            borderColor: theme.palette.info.dark,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.info.dark,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.info.dark,
        }
    },

    '& .MuiSelect-icon': {
        color: theme.palette.info.dark
    },

    '& .MuiInputBase-input': {
        
    }
}))

const InputFilter = (props: IComponent) => {
    const intl = useIntl()

    const onChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }, 300)
    
    return (
        <Container>
            <Input
                defaultValue={props.value}
                label={intl.formatMessage({id: 'app.PatientFilter'})}
                onChange={onChange}
                fullWidth
            />
        </Container>
    )
}

export default React.memo(InputFilter) 