import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {authLogout} from 'slices/auth'
import {getShortName} from '@framelink/utils'

const Avatar = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const user = useAppSelector(state => state.auth.user)

    const dispatch = useAppDispatch()

    const intl = useIntl()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
      }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        dispatch(authLogout())
    }

    return (
        <React.Fragment>
            {user &&
                <React.Fragment>
                    <Tooltip title={user.name}>
                        <IconButton onClick={handleClick} size='large' disableRipple={true}>
                            <Icon alt={user.name} sx={{bgcolor: 'primary.dark', fontSize: '1rem'}}>
                                {getShortName(user.name)}
                            </Icon>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={!!anchorEl}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>
                                {intl.formatMessage({id: 'app.Logout'})}
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Avatar
