import React, {Fragment, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {styled} from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import {useTheme} from '@mui/material/styles'
import {IPatient, IZone, ReservationNursing, NursingService} from 'flbus'
import {services} from 'enums/services'
import {Nursing} from 'interfaces/forms'

const Input = styled(TextField)(({theme}) => ({
    marginBottom: '10px',
    marginTop: '10px'
}))

const style = {
    marginBottom: '10px',
    marginTop: '10px'
}

interface IComponent {
    data: IPatient | null,
    zones: IZone[],
    onClose: () => void,
    onConfirm: (data: ReservationNursing) => void,
}

const NursingForm = (props: IComponent) => {
    const [form, setForm] = useState<Nursing>({
        info: '',
        name: props.data ? props.data.name : '',
        phone: props.data ? props.data.phone : '',
        email: props.data ? props.data.email : '',
        zoneId: '',
        address: '',
        services: [],
        request: '',
        scheduling: '',
        installment: ''
    })

    const theme = useTheme()
    const intl = useIntl()

    const onChange = (e: SelectChangeEvent<string[]> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.name === 'services' && !e.target.value.includes(services.collect.value)) {
            setForm({
                ...form,
                services: e.target.value as string[],
                request: '',
                scheduling: '',
                installment: ''
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const onConfirm = () => {
        const data = {
            ...form,
            zoneId: form.zoneId as number,
            services: form.services as NursingService[]
        }

        props.onConfirm(data)
    }

    const tax = useMemo(() => {
        if (form.zoneId) {
            const zone = props.zones.find(zone => zone.id === form.zoneId)
            return zone ? zone.tax : 0
        } else {
            return 0
        }
    }, [form.zoneId, props.zones])

    const disabled = !form.name || !form.phone || !form.zoneId || !form.address || !form.services.length
    
    return (
        <React.Fragment>
            <DialogContent>
                <Input
                    name='zoneId'
                    value={form.zoneId}
                    onChange={onChange}
                    fullWidth
                    select
                    label={intl.formatMessage({id: 'app.District'})}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    maxHeight: 300
                                }
                            }
                        }
                    }}
                >
                    {props.zones.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                    {/*<MenuItem key={props.zones.length + 1} value={props.zones.length + 1}>
                        {intl.formatMessage({id: 'app.ServiceOther'})}
                    </MenuItem>*/}
                </Input>
                <Input
                    name='address'
                    defaultValue={form.address}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.Address'})}
                />
                <FormControl fullWidth sx={style}>
                    <InputLabel>{intl.formatMessage({id: 'app.ServiceType'})}</InputLabel>
                    <Select
                        name='services'
                        value={form.services}
                        onChange={onChange}
                        multiple
                        label={intl.formatMessage({id: 'app.ServiceType'})}
                        renderValue={(selected: string[]) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={intl.formatMessage({id: `app.Service${services[value].label}`})} />
                                ))}
                            </Box>
                        )}
                    >
                        {Object.keys(services).map(key => (
                            <MenuItem
                                key={services[key].value}
                                value={services[key].value}
                            >
                                {intl.formatMessage({id: `app.Service${services[key].label}`})}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {form.services.includes(services.collect.value) &&
                    <Fragment>
                        <Divider textAlign='left' sx={{margin: '10px 0'}} light>
                            <Chip label={intl.formatMessage({id: 'app.ServiceCollect'})} />
                        </Divider>
                        <Input
                        name='request'
                        defaultValue={form.request}
                        onBlur={onChange}
                        fullWidth
                        label={intl.formatMessage({id: 'app.Request'})}
                    />
                    <Input
                        name='scheduling'
                        defaultValue={form.scheduling}
                        onBlur={onChange}
                        fullWidth
                        label={intl.formatMessage({id: 'app.Scheduling'})}
                    />
                    <Input
                        name='installment'
                        defaultValue={form.installment}
                        onBlur={onChange}
                        fullWidth
                        label={intl.formatMessage({id: 'app.Installment'})}
                    />
                    <Divider sx={{margin: '25px 0'}} light />
                    </Fragment>
                }
                <Input
                    name='info'
                    defaultValue={form.info}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.Info'})}
                    helperText={intl.formatMessage({id: 'app.SpecialInformation'})}
                />
                <Input
                    name='name'
                    defaultValue={form.name}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientName'})}
                />
                <Input
                    name='phone'
                    defaultValue={form.phone}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientPhone'})}
                />
                <Input
                    name='email'
                    defaultValue={form.email}
                    onBlur={onChange}
                    fullWidth
                    label={intl.formatMessage({id: 'app.PatientEmail'})}
                />
                <DialogContentText sx={{display: 'flex', justifyContent: 'space-between', color: theme.palette.success.dark}}>
                    <span>Subtotal</span>{tax ? `${tax} € + Serviço` : `Serviço`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {intl.formatMessage({id: 'app.Cancel'})}
                </Button>
                <Button variant='contained' disabled={disabled} onClick={onConfirm}>
                    {intl.formatMessage({id: 'app.Confirm'})}
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

export default React.memo(NursingForm) 