import flBusiness from "@framelink/flbusiness";
import IDocument from "@framelink/flbusiness/lib/interface/document";
import IDocumentValue from "@framelink/flbusiness/lib/interface/document-value";
import { log } from "@framelink/utils";

const config = {
    "username": "cmadev4", // todo - a proper special user
    "password": "123",
    "instance": "main",
    "appId": "app_1682068117721", // cmadev3

    "ecnPatients": 601,
    "fcnPatientsName": 602,
    "fcnPatientsBirthday": 627,
    "fcnPatientsEmail": 605,
    "fcnPatientsPhone": 606,
    "fcnPatientsNif": 611,
    "fcnPatientsUtente": 612,
    "fcnPatientsSecurity": 814,

    "ecnProfessionals": 588,
    "fcnProfessionalsConvencional": 1476,
    "fcnProfessionalsNonConvencional": 1477,
    "fcnProfessionalsNursing": 1481,
    "fcnProfessionalsMothersCare": 1483,
    "fcnProfessionalsName": 589,
    "fcnProfessionalsSpec": 592,
    "fcnProfessionalsImage": 622,

    "ecnDoctorsSlots": 504,
    "fcnDoctorsSlotsInfo": 515,
    "fcnDoctorsSlotsIsOnline": 1527, // todo - add to initialization
    "pointerDoctorsSlotsOwner": 590,
    "pointerDoctorsSlotsConsumer": 603,
    "pointerDoctorsSlotsStarts": 508,
    "pointerDoctorsSlotsEnds": 1154,

    "ecnNursesSlots": 705,
    "fcnNursesSlotsInfo": 714,
    "fcnNursesSlotsRequest": 716,
    "fcnNursesSlotsScheduling": 717,
    "fcnNursesSlotsInstallment": 758,
    "fcnNursesSlotsServiceInjection": 711,
    "fcnNursesSlotsServiceBandage": 712,
    "fcnNursesSlotsServiceEcg": 713,
    "fcnNursesSlotsServiceCollect": 715,
    "fcnNursesSlotsServiceCatheter": 1478,
    "fcnNursesSlotsServiceEarwash": 1479,
    "fcnNursesSlotsServiceOther": 720,
    "fcnNursesSlotsIsOutside": 1528, // todo - add to initialization
    "fcnNursesSlotsIsOnline": 1517, // todo - add to initialization
    "fcnNursesSlotsAddress": 1518, // todo - add to initialization
    "pointerNursesSlotsOwner": 896,
    "pointerNursesSlotsConsumer": 725,
    "pointerNursesSlotsStarts": 707,
    "pointerNursesSlotsEnds": 1512, // todo - add to initialization
    "pointerNursesSlotsZone": 123123,

    "ecnAppUsers": 1503,
    "fcnAppUsersLogin": 1504,
    "fcnAppUsersPassword": 1505,
    "pointerAppUsersPatient": 1510,
    "pointerAppUsersProfessional": 1508,

    "ecnVerification": 1529,
    "fcnVerificationCode": 1531,
    "fcnVerificationDue": 1532,
    "pointerVerificationPatient": 1533,

    "ecnZones": 1116,
    "fcnZonesName": 1117,
    "fcnZonesTax": 1118,

    "ecnServices": 901,
    "fcnServicesName": 902,
    "fcnServicesPrice": 903,
    "fcnServicesActive": 1415,
    "fcnServicesCenter": 1513,
    "fcnServicesHome": 1512,
    "fcnServicesMotherCarePack1": 1514,
    "fcnServicesMotherCarePack2": 1515,
    "fcnServicesMotherCarePack3": 1516,
    "fcnServicesMotherCarePack4": 1517,
    "fcnServicesMotherCarePack5": 1518,
    "fcnServicesMotherCarePack6": 1519,
    "fcnServicesMotherCarePack7": 1520,
    "fcnServicesMotherCarePack8": 1521,
    "fcnServicesMotherCarePack9": 1522,
    "fcnServicesMotherCarePack10": 1523,
    "pointerServicesServiceTypes": 908,
    "idServiceMotherCarePack1": 89,
    "idServiceMotherCarePack2": 90,
    "idServiceMotherCarePack3": 91,
    "idServiceMotherCarePack4": 92,
    "idServiceMotherCarePack5": 93,
    "idServiceMotherCarePack6": 94,
    "idServiceMotherCarePack7": 95,
    "idServiceMotherCarePack8": 96,
    "idServiceMotherCarePack9": 97,
    "idServiceMotherCarePack10": 98,

    "idServiceTypesMothersCare": 15
}

/* Enums & Interfaces */

export enum FlbusError {
    UserExists = 'UserExists',
    RegistritionFailed = 'RegistritionFailed',
    AlreadyReserved = 'AlreadyReserved',
    Unavailable = 'Unavailable',
    NotExists = 'NotExists',
    NoPhone = 'NoPhone',
    NoTemplate = 'NoTemplate',
    NotVerified = 'NotVerified'
}

export interface IRegisterData {
    nif: string
    password: string
    name: string
    phone: string
}

export interface IUser {
    id: number
    name: string
    patient: IPatient | null
    professional: IProfessional | null
}

export interface IPatient {
    id: number
    name: string
    birthday: string
    email: string
    phone: string
    nif: string
    utente: string
    security: string
}

export interface IProfessional {
    id: number
    name: string
    spec: string
    image: string
}

export interface IEvent {
    id: string
    ownerId: number | null
    consumerId: number | null
    start: string
    end: string
}

export enum ProfessionalType {
    Conventional = 'medicine',
    NonConventional = 'therapy',
    Nursing = 'nursing',
    MothersCare = 'mothers'
}

export interface IZone {
    id: number
    name: string
    tax: number
}

export interface IMotherCareService {
    id: number
    name: string
    price: number
    active: boolean
    center: boolean
    outside: boolean
}

export type ReservationPatientData = {
    info: string
    name: string
    phone: string
    email: string
}

export type ReservationAddressData = {
    zoneId: number
    address: string
}

export type ReservationDoctors = ReservationPatientData

export type ReservationNursing = ReservationPatientData & ReservationAddressData & {
    request: string
    scheduling: string
    installment: string
    services: NursingService[]
}

export type ReservationMotherCare = ReservationPatientData & ReservationAddressData & {
    isOutside: boolean
    services: number[]
}

export enum NursingService {
    Injection = 'injection',
    Bandage = 'bandage',
    Ecg = 'ecg',
    Collect = 'collect',
    Catheter = 'catheterization',
    Earwash = 'flushing',
    Other = 'other'
}

/* Maps */

const NurseServiceFieldMap = {
    [NursingService.Injection]: config["fcnNursesSlotsServiceInjection"],
    [NursingService.Bandage]: config["fcnNursesSlotsServiceBandage"],
    [NursingService.Ecg]: config["fcnNursesSlotsServiceEcg"],
    [NursingService.Collect]: config["fcnNursesSlotsServiceCollect"],
    [NursingService.Catheter]: config["fcnNursesSlotsServiceCatheter"],
    [NursingService.Earwash]: config["fcnNursesSlotsServiceEarwash"],
    [NursingService.Other]: config["fcnNursesSlotsServiceOther"]
}

const MotherCareServiceFieldMap = {
    [config["idServiceMotherCarePack1"]]: config["fcnServicesMotherCarePack1"],
    [config["idServiceMotherCarePack2"]]: config["fcnServicesMotherCarePack2"],
    [config["idServiceMotherCarePack3"]]: config["fcnServicesMotherCarePack3"],
    [config["idServiceMotherCarePack4"]]: config["fcnServicesMotherCarePack4"],
    [config["idServiceMotherCarePack5"]]: config["fcnServicesMotherCarePack5"],
    [config["idServiceMotherCarePack6"]]: config["fcnServicesMotherCarePack6"],
    [config["idServiceMotherCarePack7"]]: config["fcnServicesMotherCarePack7"],
    [config["idServiceMotherCarePack8"]]: config["fcnServicesMotherCarePack8"],
    [config["idServiceMotherCarePack9"]]: config["fcnServicesMotherCarePack9"],
    [config["idServiceMotherCarePack10"]]: config["fcnServicesMotherCarePack10"]
}

/* Utilities */

const getLinkedValue = (value: IDocumentValue): number | null => {
    if (value && value instanceof Array && value.length) {
        return value[0] as number
    }
    return null
}

const getPointerValue = (value: IDocumentValue): IDocument | null => {
    if (value && value instanceof Array && value.length) {
        return value[0] as IDocument
    }
    return null
}

/* Mappings */

const mapUser = (origin: IDocument): IUser => {
    const docPatient = getPointerValue(origin[`d${config['pointerAppUsersPatient']}`])
    const docProfessional = getPointerValue(origin[`d${config['pointerAppUsersProfessional']}`])
    const patient = docPatient ? mapPatient(docPatient) : null
    const professional = docProfessional ? mapProfessional(docProfessional) : null
    const name = professional?.name || patient?.name || origin[`c${config['fcnAppUsersLogin']}`] as string
    return { id: origin.id as number, name, patient, professional }
}

const mapPatient = (origin: IDocument): IPatient => {
    return {
        id: origin.id as number,
        name: origin[`c${config['fcnPatientsName']}`] as string,
        birthday: origin[`c${config['fcnPatientsBirthday']}`] as string,
        email: origin[`c${config['fcnPatientsEmail']}`] as string,
        phone: origin[`c${config['fcnPatientsPhone']}`] as string,
        nif: origin[`c${config['fcnPatientsNif']}`] as string,
        utente: origin[`c${config['fcnPatientsUtente']}`] as string,
        security: origin[`c${config['fcnPatientsSecurity']}`] as string
    }
}

const mapProfessional = (origin: IDocument): IProfessional => {
    const image: IDocumentValue = origin[`c${config['fcnProfessionalsImage']}`]
    return {
        id: origin.id as number,
        name: origin[`c${config['fcnProfessionalsName']}`] as string,
        spec: origin[`c${config['fcnProfessionalsSpec']}`] as string,
        image: (image && typeof image === 'string') ? flBusiness.Utils.getFileUrl(image) : ''
    }
}

const mapDoctorSlot = (origin: IDocument): IEvent => {
    return {
        id: (origin.id as number).toString(),
        ownerId: getLinkedValue(origin[`c${config['pointerDoctorsSlotsOwner']}`]),
        consumerId: getLinkedValue(origin[`c${config['pointerDoctorsSlotsConsumer']}`]),
        start: origin[`c${config['pointerDoctorsSlotsStarts']}`] as string,
        end: origin[`c${config['pointerDoctorsSlotsEnds']}`] as string
    }
}

const mapNursesSlot = (origin: IDocument): IEvent => {
    return {
        id: (origin.id as number).toString(),
        ownerId: getLinkedValue(origin[`c${config['pointerNursesSlotsOwner']}`]),
        consumerId: getLinkedValue(origin[`c${config['pointerNursesSlotsConsumer']}`]),
        start: origin[`c${config['pointerNursesSlotsStarts']}`] as string,
        end: origin[`c${config['pointerNursesSlotsEnds']}`] as string
    }
}

const mapZone = (origin: IDocument): IZone => {
    return {
        id: origin.id as number,
        name: origin[`c${config['fcnZonesName']}`] as string,
        tax: origin[`c${config['fcnZonesTax']}`] as number
    }
}

const mapMotherCareService = (origin: IDocument): IMotherCareService => {
    return {
        id: origin.id as number,
        name: origin[`c${config['fcnServicesName']}`] as string,
        price: origin[`c${config['fcnServicesPrice']}`] as number,
        active: origin[`c${config['fcnServicesActive']}`] === '1', // todo - change to flBusiness value,
        center: origin[`c${config['fcnServicesCenter']}`] === '1', // todo - change to flBusiness value,
        outside: origin[`c${config['fcnServicesHome']}`] === '1' // todo - change to flBusiness value
    }
}

/* Functions */

/**
 * FrameLink API initialization. Should be called once before any other interactions.
 *
 * @param login  User's login if exists, null by default.
 * @param pwd  User's password if exists, null by default.
 * @returns User's object if initialized with login, null if initialized without login.
 */
export const flbusInit = async (login: string | null = null, pwd: string | null = null): Promise<IUser | null> => {
    const username = config['username']
    const password = config['password']
    const instance = config['instance']
    try {
        await flBusiness.Auth.getInstances()
        await flBusiness.Auth.login(username, password, instance, config['appId'])
        if (login && pwd) {
            return flbusLogin(login, pwd)
        }
        return null
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Login into system as by login and password.
 *
 * @param login  User's login.
 * @param pwd  User's password.
 * @returns User's object if succeed, null if failed.
 */
export const flbusLogin = async (login: string, pwd: string): Promise<IUser | null> => {
    try {
        const entityAppUsers = flBusiness.Entity.get(config['ecnAppUsers'])
        if (entityAppUsers) {
            const doc = await flBusiness.Documents.getAll(entityAppUsers.id, {
                filters: [{
                    fieldId: config['fcnAppUsersLogin'],
                    value: login
                }]
            }, false)
            if (doc.length === 1 && doc[0][`c${config['fcnAppUsersPassword']}`] === pwd) {
                await flBusiness.Documents.getRelated(entityAppUsers, doc)
                return mapUser(doc[0])
            }
            return null
        } else {
            throw 'ERR_LOGIN_UNAVAILABLE'
        }
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}


/**
 * Create new user and login into system.
 *
 * @param data New user data.
 * @returns User's object if succeed, null if failed.
 */
export const flbusRegister = async (data: IRegisterData): Promise<IUser> => {
    try {
        // todo - what to do if patient with this nif already exist in system???
        /* Check if user with this login doesn't exist */
        const doc = await flBusiness.Documents.getAll(config["ecnAppUsers"], {
            filters: [{
                fieldId: config['fcnAppUsersLogin'],
                value: data.nif.trim()
            }]
        }, false)
        if (!doc.length) {
            /* Create patient */
            const patient = await flBusiness.Documents.create(config["ecnPatients"], {
                [`c${config["fcnPatientsName"]}`]: data.name.trim(),
                [`c${config["fcnPatientsPhone"]}`]: data.phone.trim(),
                [`c${config["fcnPatientsNif"]}`]: data.nif.trim()
            })
            /* Create user */
            await flBusiness.Documents.create(config["ecnAppUsers"], {
                [`c${config["fcnAppUsersLogin"]}`]: data.nif.trim(),
                [`c${config["fcnAppUsersPassword"]}`]: data.password.trim(),
                [`c${config["pointerAppUsersPatient"]}`]: [patient.id as number]
            })
            /* Make authorization */
            const authData = await flbusLogin(data.nif, data.password)
            if (authData) {
                return authData
            } else {
                throw FlbusError.RegistritionFailed
            }
        } else {
            throw FlbusError.UserExists
        }
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Get list of all known zones.
 *
 * @returns Array of all known zones.
 */
export const flbusGetZones = async (): Promise<IZone[]> => {
    return (await flBusiness.Documents.getAll(config['ecnZones'], {}, false)).map(mapZone)
}

/**
 * Get list of all available mother care services.
 *
 * @returns Array of all available mother care services.
 */
export const flbusGetMotherCareServices = async (): Promise<IMotherCareService[]> => {
    const PackIds = [
        config['idServiceMotherCarePack1'],
        config['idServiceMotherCarePack2'],
        config['idServiceMotherCarePack3'],
        config['idServiceMotherCarePack4'],
        config['idServiceMotherCarePack5'],
        config['idServiceMotherCarePack6'],
        config['idServiceMotherCarePack7'],
        config['idServiceMotherCarePack8'],
        config['idServiceMotherCarePack9'],
        config['idServiceMotherCarePack10']
    ]
    try {
        return (await flBusiness.Documents.getAll(config['ecnServices'], {
            filters: [{
                fieldId: config['pointerServicesServiceTypes'],
                value: config['idServiceTypesMothersCare']
            }]
        }, false)).map(mapMotherCareService).filter(p => p.active && PackIds.includes(p.id))
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Get list of all conventional professionals.
 *
 * @param type Type of professionals to be returned
 * @returns Array of professionals of selected type.
 */
export const flbusGetProfessionals = async (type: ProfessionalType): Promise<IProfessional[]> => {
    try {
        const fieldId: number = {
            [ProfessionalType.Conventional]: config['fcnProfessionalsConvencional'],
            [ProfessionalType.NonConventional]: config['fcnProfessionalsNonConvencional'],
            [ProfessionalType.Nursing]: config['fcnProfessionalsNursing'],
            [ProfessionalType.MothersCare]: config['fcnProfessionalsMothersCare']
        }[type]
        return (await flBusiness.Documents.getAll(config['ecnProfessionals'], {
            filters: [{
                fieldId,
                value: '1' // todo - replace with flBusiness enum
            }]
        }, false)).map(mapProfessional)
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Get list of all free slots for conventional and non-conventional professionals and reserved slots for selected patient.
 *
 * @param patientId Selected patient id.
 * @returns Array of events.
 */
export const flbusGetDoctorsSlots = async (patientId: number | null = null): Promise<IEvent[]> => {
    try {
        const slots = (await flBusiness.Documents.getAll(config['ecnDoctorsSlots'], {
            filters: [{
                fieldId: config['pointerDoctorsSlotsConsumer'],
                value: null
            }]
        }, false)).map(mapDoctorSlot).filter(s => s.ownerId)
        if (patientId) {
            const reserved = (await flBusiness.Documents.getAll(config['ecnDoctorsSlots'], {
                filters: [{
                    fieldId: config['pointerDoctorsSlotsConsumer'],
                    value: patientId
                }]
            }, false)).map(mapDoctorSlot).filter(s => s.ownerId)
            slots.push(...reserved)
        }
        const startLimit = (new Date()).toISOString()
        return slots.filter(slot => slot.consumerId || slot.start > startLimit)
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Get list of all free slots for nursing and mothers care professionals and reserved slots for selected patient.
 *
 * @param patientId Selected patient id.
 * @returns Array of events.
 */
export const flbusGetNursingSlots = async (patientId: number | null = null): Promise<IEvent[]> => {
    try {
        const slots = (await flBusiness.Documents.getAll(config['ecnNursesSlots'], {
            filters: [{
                fieldId: config['pointerNursesSlotsConsumer'],
                value: null
            }]
        }, false)).map(mapNursesSlot).filter(s => s.ownerId)
        if (patientId) {
            const reserved = (await flBusiness.Documents.getAll(config['ecnNursesSlots'], {
                filters: [{
                    fieldId: config['pointerNursesSlotsConsumer'],
                    value: patientId
                }]
            }, false)).map(mapNursesSlot).filter(s => s.ownerId)
            slots.push(...reserved)
        }
        const startLimit = (new Date()).toISOString()
        return slots.filter(slot => slot.consumerId || slot.start > startLimit)
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Make a reservation for conventional or non-conventional doctor slot.
 *
 * @param patientId Selected patient id.
 * @param slotId Selected slot id.
 * @param data Additional reservation data.
 * @returns Updated event object.
 */
export const flbusReserveDoctorSlot = async (patientId: number, slotId: string, data: ReservationDoctors): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnDoctorsSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerDoctorsSlotsConsumer"]}`]
                if (!consumer || !(consumer as number[]).length) {
                    if (!patientId) {

                    }
                    original[`c${config["pointerDoctorsSlotsConsumer"]}`] = [patientId]
                    original[`c${config["fcnDoctorsSlotsInfo"]}`] = data.info
                    original[`c${config["fcnDoctorsSlotsIsOnline"]}`] = '1' // todo - flBusiness value
                    const doc = await flBusiness.Documents.replace(config["ecnDoctorsSlots"], original)
                    // todo - update customer data
                    return mapDoctorSlot(doc)
                } else {
                    throw FlbusError.AlreadyReserved
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Make a reservation for nurse slot.
 *
 * @param patientId Selected patient id.
 * @param slotId Selected slot id.
 * @param data Additional reservation data.
 * @returns Updated event object.
 */
export const flbusReserveNursingSlot = async (patientId: number, slotId: string, data: ReservationNursing): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnNursesSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerNursesSlotsConsumer"]}`]
                if (!consumer || !(consumer as number[]).length) {
                    original[`c${config["fcnNursesSlotsIsOnline"]}`] = '1' // todo - flBusiness value
                    original[`c${config["fcnNursesSlotsIsOutside"]}`] = '1' // todo - flBusiness value
                    original[`c${config["pointerNursesSlotsConsumer"]}`] = [patientId]
                    original[`c${config["pointerNursesSlotsZone"]}`] = [data.zoneId]
                    original[`c${config["fcnNursesSlotsAddress"]}`] = data.address
                    original[`c${config["fcnNursesSlotsInfo"]}`] = data.info
                    original[`c${config["fcnNursesSlotsRequest"]}`] = data.request
                    original[`c${config["fcnNursesSlotsScheduling"]}`] = data.scheduling
                    original[`c${config["fcnNursesSlotsInstallment"]}`] = data.installment
                    data.services.forEach((value: NursingService) => {
                        const fieldId = NurseServiceFieldMap[value]
                        if (fieldId) {
                            original[`c${fieldId}`] = '1' // todo - flBusiness value
                        }
                    })
                    const doc = await flBusiness.Documents.replace(config["ecnNursesSlots"], original)
                    // todo - update customer data
                    return mapNursesSlot(doc)
                } else {
                    throw FlbusError.AlreadyReserved
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Make a reservation for mother care slot.
 *
 * @param patientId Selected patient id.
 * @param slotId Selected slot id.
 * @param data Additional reservation data.
 * @returns Updated event object.
 */
export const flbusReserveMotherCareSlot = async (patientId: number, slotId: string, data: ReservationMotherCare): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnNursesSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerNursesSlotsConsumer"]}`]
                if (!consumer || !(consumer as number[]).length) {
                    original[`c${config["fcnNursesSlotsIsOnline"]}`] = '1' // todo - flBusiness value
                    original[`c${config["fcnNursesSlotsIsOutside"]}`] = data.isOutside ? '1' : '0' // todo - flBusiness value
                    original[`c${config["pointerNursesSlotsConsumer"]}`] = [patientId]
                    if (data.isOutside) {
                        original[`c${config["pointerNursesSlotsZone"]}`] = [data.zoneId]
                        original[`c${config["fcnNursesSlotsAddress"]}`] = data.address
                        original[`c${config["fcnNursesSlotsInfo"]}`] = data.info
                    }
                    data.services.forEach((value: number) => {
                        const fieldId = MotherCareServiceFieldMap[value]
                        if (fieldId) {
                            original[`c${fieldId}`] = '1' // todo - flBusiness value
                        }
                    })
                    const doc = await flBusiness.Documents.replace(config["ecnNursesSlots"], original)
                    // todo - update customer data
                    return mapNursesSlot(doc)
                } else {
                    throw FlbusError.AlreadyReserved
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Cancel reservation for conventional or non-conventional doctor slot.
 *
 * @param patientId Selected patient id.
 * @param slotId Selected slot id.
 * @returns Updated event object.
 */
export const flbusCancelDoctorsSlot = async (patientId: number, slotId: string): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnDoctorsSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerDoctorsSlotsConsumer"]}`]
                if (consumer && (consumer as number[])[0] === patientId) {
                    original[`c${config["pointerDoctorsSlotsConsumer"]}`] = []
                    original[`c${config["fcnDoctorsSlotsInfo"]}`] = ''
                    original[`c${config["fcnDoctorsSlotsIsOnline"]}`] = '' // todo - flBusiness value
                    const doc = await flBusiness.Documents.replace(config["ecnDoctorsSlots"], original)
                    return mapDoctorSlot(doc)
                } else {
                    throw FlbusError.Unavailable
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

/**
 * Cancel reservation for nurse or mother care slot.
 *
 * @param patientId Selected patient id.
 * @param slotId Selected slot id.
 * @returns Updated event object.
 */
export const flbusCancelNursingSlot = async (patientId: number, slotId: string): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnNursesSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerNursesSlotsConsumer"]}`]
                if (consumer && (consumer as number[])[0] === patientId) {
                    original[`c${config["fcnNursesSlotsIsOnline"]}`] = '' // todo - flBusiness value
                    original[`c${config["fcnNursesSlotsIsOutside"]}`] = '' // todo - flBusiness value
                    original[`c${config["pointerNursesSlotsConsumer"]}`] = []
                    original[`c${config["pointerNursesSlotsZone"]}`] = []
                    original[`c${config["fcnNursesSlotsAddress"]}`] = ''
                    original[`c${config["fcnNursesSlotsInfo"]}`] = ''
                    original[`c${config["fcnNursesSlotsRequest"]}`] = ''
                    original[`c${config["fcnNursesSlotsScheduling"]}`] = ''
                    original[`c${config["fcnNursesSlotsInstallment"]}`] = ''
                    Object.values(NurseServiceFieldMap).forEach((fieldId: number) => {
                        original[`c${fieldId}`] = '' // todo - flBusiness value
                    })
                    Object.values(MotherCareServiceFieldMap).forEach((fieldId: number) => {
                        original[`c${fieldId}`] = '' // todo - flBusiness value
                    })
                    const doc = await flBusiness.Documents.replace(config["ecnNursesSlots"], original)
                    // todo - update customer data
                    return mapNursesSlot(doc)
                } else {
                    throw FlbusError.AlreadyReserved
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export const flbusGetCancelationLimit = (): number => {
    return 48 // todo - put param into FrameLink
}

export interface IExistingPatient {
    id: number
    nif: string
    phone: string
}

export const flbusCheckNewUser = async (nif: string): Promise<IExistingPatient | null> => {
    try {
        /* Check if user with this login doesn't exist */
        const user = await flBusiness.Documents.getAll(config["ecnAppUsers"], {
            filters: [{
                fieldId: config['fcnAppUsersLogin'],
                value: nif.trim()
            }]
        }, false)
        if (!user.length) {
            /* Create patient */
            const patient = await flBusiness.Documents.getAll(config["ecnPatients"], {
                filters: [{
                    fieldId: config['fcnPatientsNif'],
                    value: nif.trim()
                }]
            }, false)
            if (patient.length) {
                const phone = patient[0][`c${config['fcnPatientsPhone']}`]
                if (phone && typeof phone === 'string') {
                    // todo - make phone mask
                    return {
                        id: patient[0].id as number,
                        phone: `****${phone.trim().slice(-4)}`,
                        nif
                    }
                } else {
                    throw FlbusError.NoPhone
                }
            }
            return null
        } else {
            throw FlbusError.UserExists
        }
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export const flbusRequestVerificationCode = async (id: number): Promise<void> => {
    try {
        // todo - make consts
        const tpl = await flBusiness.Sms.Template.load(10)
        if (tpl) {
            /* Create verification code record */
            const due = new Date()
            // todo - make consts
            due.setMinutes(due.getMinutes() + 2)
            const code = Date.now().toString().slice(-3)
            const existing = await flBusiness.Documents.getAll(config["ecnVerification"], {
                filters: [{
                    fieldId: config["pointerVerificationPatient"],
                    value: id
                }]
            })
            let doc;
            if (existing.length) {
                doc = await flBusiness.Documents.replace(config["ecnVerification"], {
                    id: existing[0].id,
                    [`c${config["pointerVerificationPatient"]}`]: [id],
                    [`c${config["fcnVerificationCode"]}`]: code,
                    [`c${config["fcnVerificationDue"]}`]: due.toISOString()
                })
            } else {
                doc = await flBusiness.Documents.create(config["ecnVerification"], {
                    [`c${config["pointerVerificationPatient"]}`]: [id],
                    [`c${config["fcnVerificationCode"]}`]: code,
                    [`c${config["fcnVerificationDue"]}`]: due.toISOString()
                })
            }
            console.log(doc)
            /* Send an sms */
            await flBusiness.Sms.send(tpl, [doc])
        } else {
            throw FlbusError.NoPhone
        }
        // todo - send sms
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export const flbusValidateVerificationCode = async (id: number, code: string, nif: string, password: string): Promise<IUser | null> => {
    try {
        const valid = await flBusiness.Documents.getAll(config["ecnVerification"], {
            filters: [{
                fieldId: config['pointerVerificationPatient'],
                value: id
            }]
        }, false)
        if (valid.length) {
            if (valid[0][`c${config["fcnVerificationCode"]}`] === code) {
                /* Create user */
                await flBusiness.Documents.create(config["ecnAppUsers"], {
                    [`c${config["fcnAppUsersLogin"]}`]: nif.trim(),
                    [`c${config["fcnAppUsersPassword"]}`]: password.trim(),
                    [`c${config["pointerAppUsersPatient"]}`]: [id]
                })
                /* Make authorization */
                const authData = await flbusLogin(nif, password)
                if (authData) {
                    return authData
                } else {
                    throw FlbusError.RegistritionFailed
                }
            }
        }
        throw FlbusError.NotVerified
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export interface IDoctorTimemable {
    slots: IEvent[]
    patients: IPatient[]
}

export const flbusGetDoctorTimetable = async (doctorId: number): Promise<IDoctorTimemable> => {
    try {
        const entity = flBusiness.Entity.get(config['ecnDoctorsSlots'])
        if (entity) {
            let docs = (await flBusiness.Documents.getAll(config['ecnDoctorsSlots'], {
                noLF: false,
                filters: [{
                    fieldId: config['pointerDoctorsSlotsOwner'],
                    value: doctorId
                }]
            }, false))
            const startLimit = (new Date()).toISOString()
            docs = docs.filter(d => getLinkedValue(d[`c${config['pointerDoctorsSlotsConsumer']}`]) || (d[`c${config['pointerDoctorsSlotsStarts']}`] || '') > startLimit)
            await flBusiness.Documents.getRelated(entity, docs)
            const patientIds: { [id: number]: number } = {}
            const patients = docs.map(d => (d[`d${config['pointerDoctorsSlotsConsumer']}`] as IDocument[])[0] || null).filter((patient: IDocument | null) => {
                if (patient && !patientIds[patient.id as number]) {
                    patientIds[patient.id as number] = patient.id as number
                    return true
                }
            })
            return {
                slots: docs.map(mapDoctorSlot),
                patients: patients.map(mapPatient)
            }

        } else {
            throw FlbusError.NotExists
        }
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export const flbusGetDoctorEvents = async (doctorId: number): Promise<IEvent[]> => {
    try {
        const entity = flBusiness.Entity.get(config['ecnDoctorsSlots'])
        if (entity) {
            let docs = (await flBusiness.Documents.getAll(config['ecnDoctorsSlots'], {
                noLF: false,
                filters: [{
                    fieldId: config['pointerDoctorsSlotsOwner'],
                    value: doctorId
                }]
            }, false))
            const startLimit = (new Date()).toISOString()
            docs = docs.filter(d => getLinkedValue(d[`c${config['pointerDoctorsSlotsConsumer']}`]) || (d[`c${config['pointerDoctorsSlotsStarts']}`] || '') > startLimit)
            return docs.map(mapDoctorSlot)
        } else {
            throw FlbusError.NotExists
        }
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export const flbusGetAllPatients = async (): Promise<IPatient[]> => {
    try {
        return (await flBusiness.Documents.getAll(config['ecnPatients'], {}, false)).map(mapPatient)
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}

export interface IReservationPatient {
    nif: string
    name: string
    phone: string
}

export const flbusReserveTimetableSlot = async (patientId: number, slotId: string, patient: IReservationPatient | null = null): Promise<IEvent> => {
    try {
        const id = Number(slotId)
        if (!isNaN(id)) {
            const original = await flBusiness.Documents.get(config["ecnDoctorsSlots"], id)
            if (original) {
                const consumer = original[`c${config["pointerDoctorsSlotsConsumer"]}`]
                if (!consumer || !(consumer as number[]).length) {
                    if (patient) {
                        const patientDoc = await flBusiness.Documents.create(config["ecnPatients"], {
                            [`c${config["fcnPatientsName"]}`]: patient.name.trim(),
                            [`c${config["fcnPatientsPhone"]}`]: patient.phone.trim(),
                            [`c${config["fcnPatientsNif"]}`]: patient.nif.trim()
                        })
                        patientId = patientDoc.id as number
                    }
                    original[`c${config["pointerDoctorsSlotsConsumer"]}`] = [patientId]
                    original[`c${config["fcnDoctorsSlotsIsOnline"]}`] = '1' // todo - flBusiness value
                    const doc = await flBusiness.Documents.replace(config["ecnDoctorsSlots"], original)
                    // todo - update customer data
                    return mapDoctorSlot(doc)
                } else {
                    throw FlbusError.AlreadyReserved
                }
            }
        }
        throw FlbusError.NotExists
    } catch (e) {
        log('flbus error', 'red')
        log(e)
        throw e
    }
}