import React from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {useTheme} from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import {specialities} from 'enums/services'
import routes from 'enums/routes'

interface IComponent {
    speciality: string,
    mobile?: boolean,
    onChangeSpeciality: () => void
}

const Container = styled.div`
    align-items: center;
    display: flex;
    padding: 20px;
    flex-direction: column;
`

const style = {
    '& label': {
        color: 'info.dark'
    },

    '& label.Mui-focused': {
        color: 'info.dark',
    },

    '& .MuiOutlinedInput-root': {
        color: 'info.dark',

        '& fieldset': {
            borderColor: 'info.dark',
        },
        '&:hover fieldset': {
            borderColor: 'info.dark',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'info.dark',
        }
    },

    '& .MuiSelect-icon': {
        color: 'info.dark'
    }
}

const EventsFilter = (props: IComponent) => {
    const intl = useIntl()
    const theme = useTheme()

    const onChangeSpeciality = () => {
        props.onChangeSpeciality()
    }
    
    return (
        <Container style={props.mobile ? {borderBottom: `1px solid ${theme.palette.info.dark}`} : {}}>
            <TextField
                value={props.speciality}
                onChange={onChangeSpeciality}
                fullWidth
                select
                sx={props.mobile ? {} : style}
                SelectProps={{
                    IconComponent: KeyboardArrowDown,
                    MenuProps: {
                        PaperProps: {
                            sx: {
                                marginTop: '5px'
                            }
                        }
                    }
                }}
            >
                {Object.keys(specialities).map(key =>
                    <MenuItem value={specialities[key].value} key={specialities[key].value} component={Link as any} to={`/${routes.Book}/${specialities[key].value}`}>
                        {intl.formatMessage({id: `app.${specialities[key].label}`})}
                    </MenuItem>
                
                )}
            </TextField>
        </Container>
    )
}

export default React.memo(EventsFilter) 