import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useTheme} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {zonesFetch, doctorTimeslotReserve, nursingTimeslotReserve, mothersTimeslotReserve} from 'slices/booking'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import NursingForm from 'components/FormPopup/NursingForm'
import MothersForm from 'components/FormPopup/MothersForm'
import DoctorsForm from 'components/FormPopup/DoctorsForm'
import {specialities} from 'enums/services'
import {ReservationDoctors, ReservationNursing, ReservationMotherCare} from 'flbus'

interface IComponent {
    open: boolean,
    event: {
        id: string,
        title: string,
        start: string,
        category: string
    }
    onClose: () => void,
    mobile: boolean
}

const FormPopup = (props: IComponent) => {
    const zones = useAppSelector(state => state.booking.zones)
    const user = useAppSelector(state => state.auth.user)
    const data = user ? user.patient : null
    const patientId = data ? data.id : null

    const dispatch = useAppDispatch()
    const intl = useIntl()

    useEffect(() => {
        if (props.event.category === specialities.nursing.value || props.event.category === specialities.mothers.value) {
            if (!zones.length) {
                dispatch(zonesFetch())
            }
        }
    }, [dispatch, props.event.category, zones.length])

    const onConfirm = (data: ReservationDoctors | ReservationNursing | ReservationMotherCare) => {
        if (patientId) {
            if (props.event.category === specialities.medicine.value || props.event.category === specialities.therapy.value) {
                dispatch(doctorTimeslotReserve({patientId, slotId: props.event.id, data: data as ReservationDoctors}))
            }

            if (props.event.category === specialities.nursing.value) {
                dispatch(nursingTimeslotReserve({patientId, slotId: props.event.id, data: data as ReservationNursing}))
            }

            if (props.event.category === specialities.mothers.value) {
                dispatch(mothersTimeslotReserve({patientId, slotId: props.event.id, data: data as ReservationMotherCare}))
            }
        }

        props.onClose()
    }

    const date = intl.formatDate(props.event.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.event.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    return (
        <Dialog open={!!props.open} scroll='body' onClose={props.onClose} fullScreen={props.mobile}>
            <DialogTitle>
                {intl.formatMessage({id: "app.Book"})} {props.event.title} {intl.formatMessage({id: "app.On"})} {date} {intl.formatMessage({id: "calendar.At"})} {time}
            </DialogTitle>
            {props.event.category === specialities.nursing.value ?
                <NursingForm data={data} zones={zones} onConfirm={onConfirm} onClose={props.onClose} /> :
            props.event.category === specialities.mothers.value ?
                <MothersForm data={data} zones={zones} onConfirm={onConfirm} onClose={props.onClose} /> :
                <DoctorsForm data={data} onConfirm={onConfirm} onClose={props.onClose} />
            }
        </Dialog>
    )
}

export default React.memo(FormPopup) 