import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import {IHeader, View, Views} from 'interfaces/calendar'

const Container = styled.div`
    position: absolute;
    height: 50px;
    padding: 10px;
    display: flex;
    right: 0;
`

const views: Views = {
    Month: 'dayGridMonth',
    Week: 'dayGridWeek',
    Day: 'dayGridDay',
    List: 'listMonth'
}

const Header = (props: IHeader) => {
    const intl = useIntl()

    const modeChange = (e: SelectChangeEvent<unknown>) => {
        props.modeChange(e.target.value as View)
    }

    return (
        <Container>
            <Select
                value={props.view}
                onChange={modeChange}
                IconComponent={KeyboardArrowDown}
                variant='standard'
                sx={{
                    width: 100
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }
                }}
            >
                {Object.keys(views).map(item =>
                    <MenuItem key={views[item]} value={views[item]}>
                        {intl.formatMessage({id: `calendar.${item}`})}
                    </MenuItem>
                )}
            </Select>
        </Container>
    )
}

export default Header