import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {LocalStorage} from '@framelink/utils'
import {AppDispatch} from 'app/store'
import {messageSet} from 'slices/message'
import messages from 'enums/messages'
import {flbusCheckNewUser, flbusRequestVerificationCode,
    flbusValidateVerificationCode, flbusInit, flbusLogin, flbusRegister, IUser, IRegisterData,
    IExistingPatient} from 'flbus'

const nif: string | null = LocalStorage.get('nif')
const pass: string | null = LocalStorage.get('pass')

interface IAuth {
    authorized: boolean,
    user: IUser | null,
    initialized: boolean,
    pending: boolean,
    userExisting: IExistingPatient | null
}

const initialState: IAuth = {
    authorized: false,
    user: null,
    initialized: false,
    pending: false,
    userExisting: null
}

export const userExistCheck = (params: IRegisterData) => async (dispatch: AppDispatch) => {
    try {
        const response = await dispatch(userExistFetch(params.nif)).unwrap()

        if (!response) {
            dispatch(authRegister(params))
        } else {
            flbusRequestVerificationCode(response.id)
        }
    } catch (error) {
        dispatch(messageSet({
            text: messages.UserExists,
            variant: 'error'
        }))
    }
}

export const authInitialize = createAsyncThunk('auth/authInitialize', async () => {
    const response = await flbusInit(nif, pass)
    return response
})

export const userExistFetch = createAsyncThunk('auth/userExistFetch', async (nif: string, {rejectWithValue}) => {
    try {
        return await flbusCheckNewUser(nif)
    } catch (e) {
        return rejectWithValue(e)
    }
})

export const authCodeConfirm = createAsyncThunk('auth/authCodeConfirm', async (data: {id: number, code: string, password: string, nif: string}) => {
    return flbusValidateVerificationCode(data.id, data.code, data.nif, data.password)
})

export const authLogin = createAsyncThunk('auth/authLogin', async (data: {nif: string, password: string}) => flbusLogin(data.nif, data.password))

export const authRegister = createAsyncThunk('auth/authRegister', async (data: {
    nif: string, password: string, name: string, phone: string}) => flbusRegister(data))

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        authLogout: state => {
            state.authorized = false
            state.user = null
            
            LocalStorage.set('nif', null)
            LocalStorage.set('pass', null)
        },
        authRegistrationCancel: state => {
            state.userExisting = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authInitialize.pending, (state) => {
                state.pending = true
            })
            .addCase(authInitialize.fulfilled, (state, action) => {
                const user = action.payload
                state.authorized = !!user
                state.user = user
                state.pending = false
                state.initialized = true
            })
            .addCase(authInitialize.rejected, (state) => {
                state.pending = false
            })

            .addCase(authLogin.pending, (state, action) => {
                state.pending = true
            })
            .addCase(authLogin.fulfilled, (state, action) => {
                const user = action.payload
                state.authorized = !!user
                state.user = user
                state.pending = false

                LocalStorage.set('nif', action.meta.arg.nif)
                LocalStorage.set('pass', action.meta.arg.password)
            })
            .addCase(authLogin.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(authRegister.pending, (state, action) => {
                state.pending = true
            })
            .addCase(authRegister.fulfilled, (state, action) => {
                const user = action.payload
                state.authorized = !!user
                state.user = user
                state.pending = false

                LocalStorage.set('nif', action.meta.arg.nif)
                LocalStorage.set('pass', action.meta.arg.password)
            })
            .addCase(authRegister.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(userExistFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(userExistFetch.fulfilled, (state, action) => {
                state.pending = false
                state.userExisting = action.payload
            })
            .addCase(userExistFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(authCodeConfirm.pending, (state, action) => {
                state.pending = true
            })
            .addCase(authCodeConfirm.fulfilled, (state, action) => {
                const user = action.payload
                state.authorized = !!user
                state.user = user
                state.pending = false
                state.userExisting = null

                LocalStorage.set('nif', action.meta.arg.nif)
                LocalStorage.set('pass', action.meta.arg.password)
            })
            .addCase(authCodeConfirm.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export const {authLogout, authRegistrationCancel} = authSlice.actions

export default authSlice