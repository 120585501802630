import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AppDispatch} from 'app/store'
import {IEvent, IPatient, flbusGetDoctorEvents, flbusGetAllPatients,
    flbusReserveTimetableSlot, IReservationPatient} from 'flbus'

interface ITimetable {
    patients: IPatient[],
    slots: IEvent[],
    pending: boolean
}

const initialState: ITimetable = {
    patients: [],
    slots: [],
    pending: false
}

export const timetableFetch = (doctorId: number) => async (dispatch: AppDispatch) => {
    await dispatch(patientsFetch())
    dispatch(timeslotsFetch(doctorId))
}

export const timeslotReserve = (data: {
    patientId: number, slotId: string, patient: IReservationPatient | null}) => async (dispatch: AppDispatch) => {
    await dispatch(patientTimeslotReserve(data))
    dispatch(patientsFetch())
}

export const timeslotsFetch = createAsyncThunk('timetable/timeslotsFetch', async (doctorId: number) => {
    return flbusGetDoctorEvents(doctorId)
})

export const patientsFetch = createAsyncThunk('timetable/patientsFetch', async () => flbusGetAllPatients())

export const patientTimeslotReserve = createAsyncThunk('timetable/patientTimeslotReserve', async (data: {
    patientId: number, slotId: string, patient: IReservationPatient | null}) => {
        
    return flbusReserveTimetableSlot(data.patientId, data.slotId, data.patient)
})

const timetableSlice = createSlice({
    name: 'timetable',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(timeslotsFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(timeslotsFetch.fulfilled, (state, action) => {
                state.slots = action.payload
                state.pending = false
            })
            .addCase(timeslotsFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(patientsFetch.pending, (state, action) => {
                state.pending = true
            })
            .addCase(patientsFetch.fulfilled, (state, action) => {
                state.patients = action.payload
                state.pending = false
            })
            .addCase(patientsFetch.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(patientTimeslotReserve.pending, (state, action) => {
                state.pending = true
            })
            .addCase(patientTimeslotReserve.fulfilled, (state, action) => {
                const index = state.slots.findIndex(slot => slot.id === action.payload.id)

                state.slots[index] = action.payload
                state.pending = false
            })
            .addCase(patientTimeslotReserve.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default timetableSlice