import {useIntl} from 'react-intl'
import {formatDate} from '@fullcalendar/core'
import styled from '@emotion/styled'
import Popover from '@mui/material/Popover'
import {IEventPopup} from 'interfaces/calendar'

const Content = styled.div`
    max-width: 400px;
    margin: 20px;
`

const Title = styled.span`
    font-size: 13px;
    color: #a0b7be;
    letter-spacing: 0.5px;
`

const EventPopup = (props: IEventPopup) => {
    const intl = useIntl()

    const date = props.start ? formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
        locale: intl.locale
    }) : ''

    const time = props.start ? formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }) : ''

    const eventPopupContent = () => {
        if (typeof props.eventPopupContent === 'function') {
            return props.eventPopupContent(props.id, props.onClose)
        }
    }

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <Content>
                <Title>
                    {date} {intl.formatMessage({id: "calendar.At"})} {time}
                </Title>
                {eventPopupContent()}
            </Content>
        </Popover>

    )
}

export default EventPopup