import React from 'react'
import {Link, Navigate, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {useAppSelector} from 'app/hooks'
import SignIn from 'containers/SignIn'
import SignUp from 'containers/SignUp'
import Logo from 'components/Logo'
import routes from 'enums/routes'
import pattern from 'images/pattern.png'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${pattern})`,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'

}))

const Text = styled(Link)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 13,
    letterSpacing: 0.5,
    borderBottom: '1px solid',
    textDecoration: 'none'
}))

const Form = styled(Paper)(({theme}) => ({
    padding: '40px',
    width: '90%',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))

const Login = (props: {registration: boolean}) => {
    const authorized = useAppSelector(state => state.auth.authorized)
    const user = useAppSelector(state => state.auth.user)
    const userExisting = useAppSelector(state => state.auth.userExisting)
    const proId = user && user.professional ? user.professional.id : 0
    const route = proId ? `/${routes.Timetable}` : `/${routes.Book}`
    const location = useLocation()
    const intl = useIntl()

    const to = location.state && location.state.from ? location.state.from : route
    
    return (
        <React.Fragment>
        {authorized ?
            <Navigate to={to} replace={true} state={null} /> :
            <Container>
                <Form elevation={3}>
                    <Logo width={160} />
                    {props.registration ? <SignUp /> : <SignIn />}
                    {!userExisting &&
                        <Text to={props.registration ? `/${routes.Login}` : `/${routes.Registration}`}>
                            {props.registration ? intl.formatMessage({id: 'app.HaveAcc'}) : intl.formatMessage({id: 'app.DontHaveAcc'})}
                        </Text>
                    }
                </Form>
            </Container>
        }
        </React.Fragment>
    )
}

export default Login
