import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt'
import esLocale from '@fullcalendar/core/locales/es'
import {useTheme} from '@mui/material/styles'
import {IGrid, CalendarRef} from 'interfaces/calendar'

const Grid = React.forwardRef((props: IGrid, ref: CalendarRef) => {
    const theme = useTheme()

    return (
        <FullCalendar
            ref={ref}
            headerToolbar ={{
                center: 'title',
                end: '',
                start: 'prev next today'
            }}
            views={{
                dayGridMonth: {
                    dayMaxEventRows: 4
                }
            }}
            eventColor={theme.palette.primary.main}
            eventTextColor={theme.palette.info.main}
            moreLinkClick={props.moreLinkClick}
            initialView={props.defaultView}
            locales={[ ptLocale, esLocale ]}
            firstDay={1}
            locale={props.locale}
            plugins={[ dayGridPlugin, listPlugin, interactionPlugin ]}
            editable={props.editable}
            eventDurationEditable={false}
            eventDrop={props.eventDrop}
            dateClick={props.dateClick}
            eventClick={props.eventClick}
            eventDisplay='block'
            height='100%'
            dayMaxEventRows={true}
            expandRows={true}
            events={props.events}
            noEventsContent={props.listEmptyContent}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false,
                hour12: false
            }}
        />
    )
})

export default React.memo(Grid)